import styled from 'styled-components';

export const AmountBlock = styled.div`
    position: relative;
`;

export const PaymentMethodBlock = styled.div`
    border-radius: 22px;
    box-shadow: 0 0 5px 1px rgba(0, 59, 110, 0.1);
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 12px;
`;
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 599px) {
        flex-direction: column;
    }
`;
export const HeaderMiddle = styled.h3`
    color: #404b5a;
`;

export const MainHeader = styled.h1`
    color: #404b5a;
    text-align: center;
    margin-bottom: 36px;
`;

export const Input = styled.input`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
    background-color: #fff;
    font-size: 22px;

    &:focus {
        border: 1px solid #044476;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::placeholder {
        color: #c4c4c4;
    }

    &.active {
        border: 1px solid #044476;
    }
`;

export const Button = styled.button`
    width: 100%;
    max-width: 200px;
    height: 60px;
    border: none;
    border-radius: 12px;
    box-shadow: 0 0 5px 1px rgba(0, 59, 110, 0.1);
    background-color: #044476;
    font-size: 26px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin: 32px 0 16px;

    &:hover {
        background-color: #208ff5;
    }

    @media (max-width: 599px) {
        display: block;
        height: auto;
        font-size: 18px;
        width: auto;
        min-width: 120px;
        padding: 12px 24px;
        margin: 32px auto 16px;
    }
`;

export const FormBlock = styled.div``;

export const PaymentMethodsBlock = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const InputBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
    position: relative;
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    width: 22%;
    margin-right: 4%;
    margin-bottom: 4%;

    &.active {
        border-color: #044476;
    }

    &:hover {
        cursor: pointer;
    }

    &:nth-of-type(4n) {
        margin-right: 0;
    }

    @media (max-width: 959px) {
        width: 48%;
        margin-right: 4%;

        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    @media (max-width: 599px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
    }
`;

export const PaymentDiscription = styled.p`
    text-align: center;
    margin: 8px 0 0;
    color: #404b5a;
`;

export const PaymentIMG = styled.img`
    max-height: 100px;
`;

export const CurrentBalance = styled.h2`
    color: #404b5a;
    font-weight: bold;
    @media (max-width: 599px) {
        height: 15px;
        margin-top: 5px;
    }
`;

export const InputLabel = styled.label`
    position: absolute;
    top: 18px;
    right: 12px;
    color: #c4c4c4;
`;

export const ChargingBalanceBlock = styled.div`
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 22px;
    box-shadow: 0 0 5px 1px rgba(0, 59, 110, 0.1);
`;
export const AllItems = styled.div`
    padding: 16px;
`;

export const ChargeOnlyUZS = styled.p`
    color: #404B5A;
    font-style: italic;
`;
