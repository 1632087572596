import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 30px;
    color: #404b5a;
    font-weight: bold;
    margin: 30px 0;
    text-align: center;

    @media (max-width: 1279px) {
        font-size: 36px;
    }

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const CityBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 32px;
`;

export const CityName = styled.h2`
    color: #404b5a;
    margin-bottom: 16px;
    margin-top: 0;
`;

export const CityTable = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CityTableRow = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    &.header {
        font-weight: bold;
        color: #404b5a;

        &:hover {
            background-color: transparent;
            color: #404b5a;
            cursor: default;
        }
    }

    &:hover {
        background-color: #044476;
        color: #fff;
        cursor: pointer;
    }

    &:nth-last-of-type(1) {
        border-bottom: none;
    }
`;

export const CityTableRowLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #044476;
    color: #fff;
`;

export const CityTableColumn = styled.div`
    text-align: center;
    padding: 8px;
`;

export const CityTableColumnTo = styled(CityTableColumn)`
    width: 30%;
    text-align: left;

    @media (max-width: 959px) {
        width: 50%;
    }

    @media (max-width: 599px) {
        width: 70%;
    }
`;

export const CityTableColumnAirportCode = styled(CityTableColumn)`
    width: 20%;

    @media (max-width: 599px) {
        display: none;
    }
`;

export const CityTableColumnWeekDays = styled(CityTableColumn)`
    width: 20%;

    &.uppercase {
        text-transform: uppercase;
    }

    @media (max-width: 959px) {
        width: 30%;
    }
`;

export const CityTableColumnAirline = styled(CityTableColumn)`
    width: 30%;

    @media (max-width: 959px) {
        display: none;
    }
`;

export const NoResultsBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 20px;
    font-weight: bold;
    color: #404b5a;
`;
