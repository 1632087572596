import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
`;

export const TitleBlock = styled.div`
  margin: 8px 0 16px;
  font-size: 16px;
  font-weight: bold;
  color: #404b5a;
`;

export const HotelName = styled(TitleBlock)`
  margin: 16px 0 8px;
`;

export const DescriptionBlock = styled.div`
  color: #404b5a;
  margin-bottom: 16px;
`;
