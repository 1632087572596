import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Input, InputLabel } from '@material-ui/core';
import device from 'current-device';
import NativeSelectStatelessUI from '../NativeSelectStatelessUI/NativeSelectStatelessUI';
import { ErrorsFieldBlock } from '../../../../styles/common';
import InputMessage from '../InputMessage/InputMessage';
import useLocalization from '../../../../contexts/localization/hook';

function SelectFormUI(props) {
    const { t } = useLocalization();
    const { input, items, color, fontSize, label, width, height, onClick, disabled, fieldError, value } = props;

    const useCustomStyles = makeStyles({
        formControl: {
            position: 'relative',
        },
        inputLabel: {
            fontSize: 12,
            color: '#9da8ae',
            position: 'absolute',
            top: 8,
            left: 16,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.41',
            letterSpacing: 'normal',
            zIndex: 1,
        },
    });

    const useStyles = makeStyles({
        root: {
            overflow: 'hidden',
            backgroundColor: '#fff',
            color: color ? color : '#000000',
            fontSize: fontSize ? fontSize : 16,
            borderRadius: 12,
            border: `${fieldError || (props?.meta?.touched && props?.meta?.error) ? '2px' : '1px'} solid ${
                fieldError || (props?.meta?.touched && props?.meta?.error) ? '#c62828' : '#C3D6E8'
            }`,
            padding: '27px 0 14px 16px',
            width: width ? width : 280,
            height: height ? height : 18,
            boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
        },
        select: {
            '&:focus': {
                backgroundColor: '#fff',
                borderRadius: 12,
            },
            '&:hover': {
                backgroundColor: '#fff',
                borderRadius: 12,
            },
        },
        icon: {
            color: color ? color : '#000000',
            top: `calc(50% - 10px)`,
            right: 10,
        },
    });

    const inputStyles = makeStyles({
        root: {
            display: 'flex',
        },
    });

    const StyledMenuItem = withStyles(theme => ({
        root: {
            '&:hover': {
                backgroundColor: '#9CCFFE',
            },
            '&.Mui-selected': {
                backgroundColor: '#044476 !important',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#044476 !important',
                },
            },
        },
    }))(MenuItem);

    const classes = useCustomStyles();

    const styles = useStyles();

    const inputClasses = inputStyles();

    const desktopSelect = () => (
        <Select
            input={<Input {...input} classes={inputClasses} />}
            disabled={disabled}
            onClick={onClick}
            disableUnderline={true}
            displayEmpty
            inputProps={{
                classes: styles,
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
        >
            {items.map(({ label, value, disabled = false }) => (
                <StyledMenuItem disableRipple key={value} value={value} disabled={disabled}>
                    {label}
                </StyledMenuItem>
            ))}
        </Select>
    );

    return (
        <div className={classes.formControl}>
            {label && (
                <InputLabel className={classes.inputLabel} focused={false}>
                    {label}
                </InputLabel>
            )}
            {device.type === 'desktop' ? desktopSelect() : <NativeSelectStatelessUI {...props} />}

            <ErrorsFieldBlock>
                {props?.meta?.touched && props?.meta?.error && <InputMessage text={t[props?.meta?.error]} />}
                {fieldError && <InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </div>
    );
}

export default SelectFormUI;
