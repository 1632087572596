import React, { useCallback, useState } from 'react';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Component, InputBlock, Label, Input, ErrorText, FormHideStyle } from './components';
import { FormGroup } from '@material-ui/core';

const TextInput = ({
    value = null,
    onChangeText = () => null,
    error = null,
    label = null,
    required = false,
    type = 'text',
    placeholder = null,
    onBlur = () => null,
    touched = false,
    maxLength = 60,
}) => {
    const handleChange = useCallback(e => {
        onChangeText(e.target.value);
    }, []);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisible = () => {
        setPasswordVisible(visible => !visible)
    };

    return (
        <FormGroup>
            <Component>
                <InputBlock error={touched && error}>
                    {label && (
                        <Label>
                            {required && <span>*</span>} {label}:
                        </Label>
                    )}
                    <Input
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                        type={passwordVisible ? 'text' : type}
                        maxLength={maxLength}
                        onBlur={onBlur}
                    />

                    {type === 'password' && (
                        <FormHideStyle onClick={togglePasswordVisible}>{passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}</FormHideStyle>
                    )}

                </InputBlock>
                {touched && error && <ErrorText>{error}</ErrorText>}
            </Component>
        </FormGroup>
    );
};

export default TextInput;
