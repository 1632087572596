import React from "react";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import WhatshotIcon from "@material-ui/icons/Whatshot";

import useLocalization from "../../../../contexts/localization/hook";

import { StyledButton, Wrapper } from "./components";

const ToursSortingTabComponent = ({ sorting, onChangeSorting }) => {
  const { t } = useLocalization();

  return (
    <Wrapper>
      <StyledButton onClick={() => onChangeSorting("new")} $isActive={sorting === "new"}>
        <ThumbUpIcon />
        <span>{t.tours_new}</span>
      </StyledButton>
      
      <StyledButton onClick={() => onChangeSorting("lastMinute")} $isActive={sorting === "lastMinute"}>
        <WhatshotIcon />
        <span>{t.tours_last_minute}</span>
      </StyledButton>

      <StyledButton onClick={() => onChangeSorting("cheap")} $isActive={sorting === "cheap"}>
        <MonetizationOnIcon />
        <span>{t.tours_cheap}</span>
      </StyledButton>
    </Wrapper>
  );
};

export default ToursSortingTabComponent;
