import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import config from '../../../../../../config';

import useLocalization from '../../../../../../contexts/localization/hook';

import {
    CarouselBlock,
    CarouselDot,
    CarouselDots,
    CarouselItem,
    CarouselItemImage,
    CarouselItemLink,
    StyledSlider,
} from './styles';

const Carousel = ({ tours }) => {
    const { envConfigs } = config;
    const { language } = useLocalization();

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        arrows: false,
        dots: true,
        customPaging: () => <CarouselDot />,
        dotsClass: `slick-dots`,
        autoplay: false,
        speed: 300,
        draggable: true,
        useCSS: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1299,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <CarouselBlock>
            <Grid item xs={12}>
                <StyledSlider {...settings}>
                    {tours.map(item => {
                        return (
                            <CarouselDots key={item.id}>
                                <CarouselItem>
                                    <NavLink to={`/tour/${item.id}`}>
                                        <CarouselItemImage
                                            src={item.images.length > 0 ? `${envConfigs.uploadHost}${item.images[0].url}` : null}
                                        />
                                    </NavLink>

                                    <CarouselItemLink to={`/tour/${item.id}`}>
                                        {item.name[language]}
                                    </CarouselItemLink>
                                </CarouselItem>
                            </CarouselDots>
                        );
                    })}
                </StyledSlider>
            </Grid>
        </CarouselBlock>
    );
};

export default Carousel;