import styled from 'styled-components';

export const Block = styled.div`
    width: 100%;
`;

export const FlightTypeToggleButton = styled.button`
    background-color: transparent;
    color: #044476;
    border: none;
    padding: 4px 0;
    margin: 12px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        cursor: pointer;
        opacity:0.7;
    }

    @media (max-width: 959px) {
        margin: 16px 0;
    }
`;

export const FlightTypeIcon = styled.img`
    margin-right: 8px;
`;

export const SubmitButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: ${props => (props.smallSearchBlock ? '16px' : '5rem')} auto;

    @media (max-width: 599px) {
        margin: 16px 0 0 0;
    }
`;

export const SubmitButton = styled.button`
    height: 60px;
    background-color: #84b6df;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    max-width: 280px;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 959px) {
        font-weight: bold;
        font-size: 28px;
        text-transform: uppercase;
    }

    @media (max-width: 599px) {
        font-weight: bold;
        font-size: 20px;
        text-transform: uppercase;
    }
`;
export const RoutesAndHistorySearchButtons = styled.div`
    display: flex;
    align-items: center;    

    @media (max-width: 959px) {
        justify-content: space-between;
    }
`;
