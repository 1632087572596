import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';

export const CarouselBlock = styled('div')({
    marginTop: 8,
    marginBottom: 16,
});

export const CarouselItem = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    padding: 8,
});

export const CarouselItemLink = styled(NavLink)({
    paddingLeft: 16,
    paddingRight: 0,
    color: '#404b5a',
    fontSize: 20,
    fontWeight: 600,
    textDecoration: 'none',
    textTransform: 'uppercase',

    '@media (max-width: 599px)': {
        fontSize: 16,
    },
});

export const CarouselItemImage = styled('img')({
    display: 'block',
    width: '100%',
    height: 225,
    marginTop: 0,
    marginBottom: 8,
    borderRadius: 16,

    '@media (max-width: 1279px)': {
        height: 243,
    },

    '@media (max-width: 959px)': {
        height: 300,
    },

    '@media (max-width: 599px)': {
        height: 'calc(175px + (310 - 175) * ((100vw - 360px) / (600 - 360)))',
    },
});

export const CarouselDots = styled('div')({
    bottom: 'auto',
    top: 'calc(100% + 8px)',

    '& > .slick-active > div': {
        backgroundColor: '#4ea7bf',
    },
});

export const CarouselDot = styled('div')({
    width: 16,
    height: 16,
    backgroundColor: '#e4e9f3',
    borderRadius: '50%',

    '@media (max-width: 959px)': {
        width: 12,
        height: 12,
    },

    '@media (max-width: 599px)': {
        width: 10,
        height: 10,
    },
});

export const StyledSlider = styled(Slider)({
    "& .slick-dots": {
        bottom: 'auto',
        top: 'calc(100% + 8px)',

        [`& .slick-active > div`]: {
            backgroundColor: '#4ea7bf',
        },
    },
});
