import React, { useState } from "react";

import { getImgFromUploads } from "../../../../lib/getImage";

import { ImageBlock, ImageThumbnail, ImagesBlock } from "./components";

const TourImagesComponent = ({ images = [] }) => {
  const [activeImage, setActiveImage] = useState(images?.[0]?.url);

  if (!images.length) return null;

  return (
    <div>
      <ImageBlock src={getImgFromUploads(activeImage)} alt="tour-image" />

      <ImagesBlock>
        {images?.map(img => (
          <ImageThumbnail
            key={img.id}
            $imageUrl={getImgFromUploads(img.url)}
            alt="tour-image"
            onClick={() => setActiveImage(img.url)}
            className={img.url === activeImage && "active"}
          />
        ))}
      </ImagesBlock>
    </div>
  )
};

export default TourImagesComponent;