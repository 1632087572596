import styled from "styled-components";

export const ImageBlock = styled.img`
  border-radius: 4px;
  margin-bottom: 8px;
  max-width: 100%;

  @media (max-width: 599px) {
    margin-bottom: 0;
  }
`;

export const ImagesBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

export const ImageThumbnail = styled.div`
  background: url(${props => props.$imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  max-width: 100%;
  height: 96px;
  border: 3px solid #fff;

  &.active {
    border: 3px solid #4ea7bf;
  }

  @media (max-width: 599px) {
    height: 48px;
    border: 1px solid #fff;

    &.active {
      border: 1px solid #4ea7bf;
    }
  }
`;
