import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from '@material-ui/core';

import {
    Wrapper,
    Button,
    MenuIcon,
    StyledLink,
    LinkForAgents,
    ClientName,
    ClientLogin,
    ClientBalanceBlock,
    ClientBalanceTitle,
    ClientBalanceValue,
    ExitButton,
} from './components';
import ModalComponent from '../../../../../../ui/modal';
import useLocalization from '../../../../../../../../contexts/localization/hook';
import config from '../../../../../../../../config';
import { setAppConfigs } from '../../../../../../../../store/appConfigs/actions';
import SearchOrder from '../../../../../../searchOrder';

const MainMenu = () => {
    const { isAuth, currency } = useSelector(store => store.appConfigs);
    const user = useSelector(state => state.clientData);
    const { t } = useLocalization();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);

    const { agentsLink } = config.envConfigs;

    const handleLogout = () => {
        dispatch(
            setAppConfigs({
                isAuth: false,
            })
        );

        setIsActive(false);
        window.localStorage.removeItem('token');
        window.location.href = '/';
    };

    return (
        <Wrapper>
            <Button onClick={() => setIsActive(true)}>
                <MenuIcon />
            </Button>

            <ModalComponent active={isActive} onClose={() => setIsActive(false)} title={t.menu}>
                {!isAuth ? (
                    <>
                        <StyledLink to="/login" text={t.log_in} onClick={() => setIsActive(false)} />

                        <StyledLink to="/registration" text={t.registration} onClick={() => setIsActive(false)} />

                        <SearchOrder />

                        <LinkForAgents href={`${agentsLink}`}>{t.agents}</LinkForAgents>
                    </>
                ) : (
                    <>
                        <ClientName>
                            {user.firstName} {user.lastName}
                        </ClientName>
                        <ClientLogin>{user.login}</ClientLogin>
                        <ClientBalanceBlock>
                            <ClientBalanceTitle>{t.your_id}:</ClientBalanceTitle>
                            <ClientBalanceValue>{user.id}</ClientBalanceValue>
                        </ClientBalanceBlock>
                        <Divider />

                        <ClientBalanceBlock>
                            <ClientBalanceTitle>{t.balance}:</ClientBalanceTitle>

                            <ClientBalanceValue>
                                {user.balance || 0}
                                <span>{user.currency || currency}</span>
                            </ClientBalanceValue>
                        </ClientBalanceBlock>

                        <Divider />

                        <StyledLink to="/orders" onClick={() => setIsActive(false)} text={t.orders} marginTop />

                        <StyledLink to="/transactions" onClick={() => setIsActive(false)} text={t.transactions} />

                        <StyledLink to="/userProfile" onClick={() => setIsActive(false)} text={t.my_profile} />

                        <StyledLink
                            to="/rechargeBalance"
                            onClick={() => setIsActive(false)}
                            text={t.recharging_balance}
                        />

                        <ExitButton onClick={() => handleLogout()}>{t.log_out}</ExitButton>
                    </>
                )}
            </ModalComponent>
        </Wrapper>
    );
};

export default MainMenu;
