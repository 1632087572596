import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    carouselDots: {
        bottom: 'auto',
        top: '100%',

        '& > .slick-active > div': {
            backgroundColor: '#044476',
        },

        '@media screen and (max-width: 959px)': {
            top: 'calc(100% + 8px)',
        },
    },
    carouselDot: {
        width: '16px',
        height: '16px',
        backgroundColor: '#E4E9F3',
        borderRadius: '50%',
        '@media screen and (max-width: 959px)': {
            width: 14,
            height: 14,
        },

        '@media screen and (max-width: 959px)': {
            width: 12,
            height: 12,
        },
    },
});
