import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const fadeIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    
    to {
        transform: translateY(100%); 
        opacity: 0;
    }
`;

export const Wrapper = styled('div')(({ isShow }) => ({
    display: isShow ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 250,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
}));

export const Backdrop = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 251;
    backgroundColor: transparent,
    animation: ${props => (props.isShow ? fadeIn : fadeOut)} 0.3s ease forwards;
`;

export const Modal = styled('div')`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 700px;
    position: relative;
    border-radius: 8px;

    @media (max-width: 959px) {
        margin: 0 16px;
        width: 100%;
    }
`;

export const CloseButton = styled(IconButton)(({
    position: 'absolute !important',
    right: 12,
    top: 10,
}));


export const StyledCloseIcon = styled(Close)`
    color: #404b5a;
`;


export const Text = styled('p')`
    padding: 44px 16px 16px;
    color: #404b5a;
    margin: 0;
    font-size: 18px;
    text-align: center;
`;

export const ButtonBlock = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
`;

export const Button = styled(NavLink)`
    padding: 12px 24px;
    border-radius: 12px;
    background-color: #8546c9;
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
`;