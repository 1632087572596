import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    @media (max-width: 1279px) {
        flex-wrap: wrap;
    }
`;

export const From = styled.div`
    height: 60px;
    background-color: rgb(78, 167, 191);
    width: 285px;
    border-radius: 12px;
    margin-right: 8px;
    position: relative;
    box-shadow: ${props => (props.notFilled ? '0px 0px 4px 2px #ff0000' : 'none')};

    @media (max-width: 1279px) {
        width: 440px;
        margin-bottom: 8px;
    }

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 8px;
        margin-right: 0;
    }
`;

export const ReplaceRouteButton = styled.button`
    position: absolute;
    top: 50%;
    left: 273px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 11px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
    z-index: 11;
    color: #044476;
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 6px rgb(0 0 0 / 40%);
    }

    @media (max-width: 1279px) {
        left: 48.5%;
        transform: translateX(-50%) translateZ(0);
        top: 14px;
    }

    @media (max-width: 959px) {
        left: auto;
        right: 10px;
        top: 48px;
        transform: rotate(90deg);
    }
`;

export const ReplaceRouteButtonLeftIcon = styled.div`
    transform: rotate(0.5turn) translateX(0);
    margin-bottom: 2px;

    & > svg {
        display: block;
    }
`;

export const ReplaceRouteButtonRightIcon = styled.div`
    & > svg {
        display: block;
    }
`;

export const To = styled.div`
    height: 60px;
    background-color: rgb(78, 167, 191);
    width: 285px;
    margin-right: 8px;
    position: relative;
    border-radius: 12px;
    box-shadow: ${props => (props.notFilled ? '0px 0px 4px 2px #ff0000' : 'none')};

    @media (max-width: 1279px) {
        width: 440px;
        margin-bottom: 8px;
    }

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 8px;
        border-radius: 12px;
        margin-right: 0;
    }
`;

export const DateFromTo = styled.div`
    display: flex;
    position: relative;
    height: 60px;
    width: 397px;
    margin-right: 8px;
    border-radius: 12px;
    box-shadow: ${props => (props.notFilled ? '0px 0px 4px 2px #ff0000' : 'none')};

    @media (max-width: 1279px) {
        width: 440px;
        border-radius: 12px;
    }

    @media (max-width: 959px) {
        margin-bottom: 8px;
        width: 100%;
        border-radius: 12px;
        margin-right: 0;
    }
`;

export const PassengersAndClass = styled.div`
    height: 60px;
    background-color: rgb(78, 167, 191);
    width: 236px;
    border-radius: 12px;
    position: relative;

    @media (max-width: 1279px) {
        width: 440px;
        border-radius: 12px;
    }

    @media (max-width: 959px) {
        width: 100%;
        border-radius: 12px;
    }
`;

export const SubmitButtonContainer = styled.div`
    width: 276px;
    height: 70px;
    margin: 10rem auto;

    // @media (max-width: 1279px) {
    //     width: 226.5px;
    //     margin-left: 0;
    // }

    @media (max-width: 959px) {
        width: 100%;
        margin-top: 12px;
    }
`;

export const SubmitButton = styled.button`
    height: 60px;
    background-color: #84b6df;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }
`;
