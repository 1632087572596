import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    destinationsContainer: {
        backgroundColor: '#fff',
        padding: '16px',
        marginBottom: '32px',
        borderRadius: '12px',
        boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
    },
    destinationsTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        marginBottom: '8px',
        backgroundColor: '#044476',
        boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
        width: '100%',
        borderRadius: '12px 12px 0 0',
        color: '#fff',
        textTransform: 'uppercase',
        textAlign: 'center',

        '@media (max-width: 599px)': {
            fontSize: '20px',
        },
    },
    destinationsTable: {
        minWidth: '552px',

        '@media (max-width: 599px)': {
            minWidth: 0,
        },
    },
    destinationGrid: {
        '@media (max-width: 599px)': {
            display: 'none',
        },
    },
    destinationBlock: {
        marginBottom: '16px',
        overflowX: 'auto',
    },
    destinationBlockTitle: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#044476',
        marginBottom: '8px',

        '@media (max-width: 599px)': {
            fontSize: '18px',
        },
    },
    showMoreButton: {
        fontSize: '18px',
        fontWeight: 'bold',
        border: '1px solid #044476',
        borderRadius: '24px',
        backgroundColor: 'transparent',
        height: '35px',
        color: '#044476',
        cursor: 'pointer',
        marginBottom: '32px',
        padding: '0 16px',
    },
    buttonBlock: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    bodyList: {
        color: '#000',

        '&:hover': {
            backgroundColor: '#044476',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    circularProgress: {
        color: '#fff',
    },
    successResult: {
        color: '#3eab50',
    },
    errorResult: {
        color: '#e81b1b',
    },
    carouselBlock: {
        marginTop: '8px',
        marginBottom: '32px',
    },
    carouselItemBlock: {
        height: '120px',
    },
    carouselItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px',
        height: '100%',
        boxSizing: 'border-box',
    },
    carouselItemImage: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
    tablecell: {
        color: 'inherit',
    },
    dayCell: {
        textTransform: 'uppercase',
    },
    mobileDisplayNone: {
        '@media (max-width: 599px)': {
            display: 'none',
        },
    },
});
