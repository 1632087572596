import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Input, IataCode } from './components';
import Dropdown from '../../dropdown';

import configs from '../../../../../../config';
import API from '../../../../../../lib/api';
import useLocalization from '../../../../../../contexts/localization/hook';
import { CircularProgress } from '@material-ui/core';

const AirportToField = ({ isSimple, selected, onSelect, index = null }) => {
    const { defaultCities } = configs;
    const { t, language } = useLocalization();

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);
    const [items, setItems] = useState(defaultCities);

    useEffect(() => {
        if (value.length === 0) {
            setItems(defaultCities);
        }
    }, [value]);

    useEffect(() => {
        checkSelected();
    }, [isFocus]);

    useEffect(() => {
        if (selected) {
            setValue(selected.value[language]);
        } else {
            setValue('');
        }
    }, [selected]);

    const checkSelected = () => {
        if (!isFocus && value.length > 0 && !selected) {
            onSelect(items[0], index);
        }
    };

    const getCities = useCallback(
        _.debounce(value => {
            setLoading(true);
            API.getCities(value).then(response => {
                setItems(response);
                setLoading(false);
            });
        }, 300),
        [API, setItems]
    );

    const handleFocus = () => {
        if (!selected) {
            setIsFocus(true);
        }
    };

    const handleChange = e => {
        setValue(e.target.value);
        setIsFocus(true);

        if (e.target.value.length > 1) {
            getCities(e.target.value);
        }
    };

    const handleSelect = city => {
        setIsFocus(false);
        onSelect(city, index);
    };

    const handleClose = () => {
        setIsFocus(false);

        if (selected) {
            setValue(selected.value[language]);
        }
    };

    const renderCodeOrLoading = () => {
        if (loading) return <CircularProgress size={16} style={{ color: '#fff' }} />;

        if (selected) return selected.code;
    };

    return (
        <>
            <Input
                type="text"
                placeholder={t.route_to}
                onFocus={() => handleFocus()}
                onChange={e => handleChange(e)}
                value={value}
                isSimple={isSimple}
                autoComplete="off"
            />
            <IataCode active>{renderCodeOrLoading()}</IataCode>
            {isFocus && <Dropdown isActive={isFocus} items={items} onSelect={handleSelect} handleClose={handleClose} />}
        </>
    );
};

export default AirportToField;
