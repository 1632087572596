import styled from "styled-components";

export const NotFoundOptionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #404b5a;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export const StyledBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0;

  @media (max-width: 959px) {
    flex-wrap: wrap;
    gap: 32px;
    position: relative;
  }
`;

export const StyledFiltersBlock = styled.div`
  width: 30%;
  max-width: 30%;

  @media (max-width: 959px) {
    width: 100%;
    max-width: 100%;
    order: 2;
    position: sticky;
    bottom: 16px;
  }
`;

export const StyledResultsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 67%;
  max-width: 67%;

  @media (max-width: 959px) {
    width: 100%;
    max-width: 100%;
    order: 1;
  }
`;

export const StyledToursBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
