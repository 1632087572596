import React, { useCallback } from 'react';

import { Component, InputBlock, Label, Input, ErrorText } from './components';

const MaskedTextInput = ({
    value = null,
    onChangeText = () => null,
    error = null,
    label = null,
    required = false,
    type = 'text',
    placeholder = null,
    onBlur = () => null,
    touched = false,
    mask = [],
}) => {
    const handleChange = useCallback(e => {
        onChangeText(e.target.value);
    }, []);

    return (
        <Component>
            <InputBlock error={touched && error}>
                {label && (
                    <Label>
                        {required && <span>*</span>} {label}:
                    </Label>
                )}
                <Input
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    type={type}
                    onBlur={onBlur}
                    mask={mask}
                />
            </InputBlock>
            {touched && error && <ErrorText>{error}</ErrorText>}
        </Component>
    );
};

export default MaskedTextInput;
