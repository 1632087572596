import styled from "styled-components";

export const StyledBlock = styled.div`
  padding: 32px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 959px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledFiltersBlock = styled.div`
  width: 35%;
  max-width: 35%;

  @media (max-width: 959px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledResultsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 63%;
  max-width: 63%;

  @media (max-width: 959px) {
    width: 100%;
    max-width: 100%;
  }
`;
