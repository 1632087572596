import React, { useState } from "react";

import useLocalization from "../../../../contexts/localization/hook";

import ModalComponent from "../../ui/modal";

import ToursFilterSortingComponent from "../ToursFilterSortingComponent";
import ToursFilterTypesComponent from "../ToursFilterTypesComponent";
import ToursFilterPriceComponent from "../ToursFilterPriceComponent";
import ToursFilterHotelsComponent from "../ToursFilterHotelsComponent";
import ToursFilterCitiesComponent from "../ToursFilterCitiesComponent";

import {
  Button,
  ButtonBlock,
  DesktopBlock,
  FilterButton,
  FilterIcon,
  MobileBlock,
  Wrapper,
} from "./components";

const ToursFiltersComponent = ({ filters, onChangeFilters, sorting, onChangeSorting }) => {
  const { t } = useLocalization();

  const [modalIsActive, setModalIsActive] = useState(false);
    
  const isResetShowed = filters.types.selected.length > 0
    || filters.price.selected.min !== filters.price.data.min
    || filters.price.selected.max !== filters.price.data.max
    || filters.hotels.selected.length > 0
    || filters.cities.selected.length > 0;

  const Filters = () => (
    <>
      <ToursFilterSortingComponent
        sorting={sorting}
        onChangeSorting={onChangeSorting}
      />

      <ToursFilterTypesComponent
        items={filters.types.items}
        selected={filters.types.selected}
        onChange={onChangeFilters}
      />

      <ToursFilterPriceComponent
        data={filters.price.data}
        selected={filters.price.selected}
        onChange={onChangeFilters}
      />

      <ToursFilterHotelsComponent
        items={filters.hotels.items}
        selected={filters.hotels.selected}
        onChange={onChangeFilters}
      />

      <ToursFilterCitiesComponent
        items={filters.cities.items}
        selected={filters.cities.selected}
        onChange={onChangeFilters}
        hasBorderBottom={isResetShowed}
      />

      {isResetShowed && (
        <ButtonBlock>
          <Button>
            {t.reset_filters}
          </Button>
        </ButtonBlock>
      )}
    </>
  );

  return (
    <>
      <DesktopBlock>
        <Filters />
      </DesktopBlock>

      <MobileBlock>
        <ButtonBlock>
          <FilterButton onClick={() => setModalIsActive(true)}>
            <FilterIcon />
            {t.filters}
          </FilterButton>
        </ButtonBlock>

        <ModalComponent
          active={modalIsActive}
          onClose={() => setModalIsActive(false)}
          title={t.filters}
        >
          <Wrapper>
            <Filters />
          </Wrapper>
        </ModalComponent>
      </MobileBlock>
    </>
  );
};

export default ToursFiltersComponent;
