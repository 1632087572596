import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router';

import { setAppConfigs } from '../../store/appConfigs/actions';
import API from '../../lib/api';
import PopulartDestinationsPage from '../../pages/popularDestinations';
import useLocalization from '../../contexts/localization/hook';
import envConfigs from '../../config/envConfigs';
import { is } from 'immutable';

const PopulartDestinations = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useLocalization();
    const [isLoadind, setIsLoading] = useState(true);
    const [destinations, setDestinations] = useState([]);

    useEffect(() => {
        document.title = `${t.page_popular_destinations} | ${envConfigs.appTitle}`;

        dispatch(
            setAppConfigs({
                isStickySearchBlock: false,
                isSmallSearchBlock: false,
                isFullScreenSearchBlock: false,
            })
        );

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        getPopularDestinations();
    }, [location]);

    const getPopularDestinations = async () => {
        await API.getPopularDestinations().then(response => {
            parseData(response.data);
        });
    };

    const parseData = data => {
        let result = [];
        let finalArray = [];

        data.map(item => {
            result.push({
                departureAirport: item.departure,
                arrivalAirport: item.arrival,
                flightDays: getDaysIndex(JSON.parse(item.days_of_week)),
                supplier: item.airCompany,
            });
        });

        result.map(value => {
            const index = finalArray.findIndex(x => x.city.code_iata === value.departureAirport.city.code_iata);
            if (index > -1) {
                finalArray[index].flights.push(value);
            } else {
                finalArray.push({
                    city: value.departureAirport.city,
                    airportCode: value.departureAirport.code_iata,
                    flights: [value],
                });
            }
        });

        finalArray = finalArray.sort((a, b) =>
            a.city['name_ru'] > b.city['name_ru'] ? 1 : b.city['name_ru'] > a.city['name_ru'] ? -1 : 0
        );
        setDestinations(finalArray);
        setIsLoading(false);
    };

    const getDaysIndex = days => {
        const daysIndexes = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

        return days.map(day => {
            return daysIndexes.findIndex(dayIndex => dayIndex === day);
        });
    };

    return <PopulartDestinationsPage loading={isLoadind} destinations={destinations} />;
};

export default withRouter(PopulartDestinations);
