import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerGrid = styled(Grid)`
    flex-grow: 1;
    background-color: #fff;
`;

export const Separator = styled.div`
    height: 16px;
    background-color: #fff;
`;

export const FlightDetailsBlock = styled.div`
    text-align: center;
    border: none;
    padding: 0 16px;
    cursor: pointer;
    color: ${props => (props.showDetailsBlock ? '#044476' : '#9da8ae')};
    margin-bottom: 4px;
`;

export const TimeMessage = styled.div`
    background-color: #fff;
    font-size: 14px;
    color: #7e878b;
    padding-bottom: 16px;

    @media (max-width: 599px) {
        padding-left: 0;
        text-align: center;
    }
`;
