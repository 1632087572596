import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100%;

    & > * {
        user-select: none;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Line = styled.div`
    margin: 16px 0 12px;
    height: 1px;
    width: 100%;
    background-color: #dfe5ec;
`;

export const Footer = styled.div`
    height: 48px;
`;

export const Button = styled.button`
    height: 48px;
    width: 100%;
    border-radius: 8px;
    background-color: #044476;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
`;
