import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRoutes } from "../../../../../store/searchParams/actions";
import useLocalization from "../../../../../contexts/localization/hook";
import departureArrival from "../../../../../assets/icons/departure_and_arrival.svg"

import moment from "moment";
import API from "../../../../../lib/api";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircularProgress } from "@material-ui/core";

import { Container } from "@material-ui/core";
import {
  Destinations,
  Figcaption,
  Figure,
  ItemWrapper,
  SpinerLoader,
  ShowMore,
  Header,
  Icon,
  Title,
  Airport,
} from "./components";

const PopularDestinations = ({
  destination,
  cities,
  airports,
  airlines,
  rtl,
}) => {
  const [showMoreDestinations, setShowMoreDestinations] = useState(6);
  const { t, language } = useLocalization();
  const { currency } = useSelector((state) => state.appConfigs);
  const { flights, from } = destination || {};
  const { routes } = useSelector((state) => state.searchParams);

  const flightsLimit = flights.slice(0, showMoreDestinations);

  const [spinerLoader, setSpinerLoader] = useState(0);

  const dispatch = useDispatch();

  const price = (flight) => {
    return flight[`pop_price_${currency.toLowerCase()}`];
  };

  const daysIndexes = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const dayOfTheWeeks = (weekDays) => {
    const parsedweekDays = JSON.parse(weekDays);

    const translationWeekDays = parsedweekDays.map((day) => {

      switch (day) {
        case daysIndexes[0]:
          return "пн";

        case daysIndexes[1]:
          return "вт";

        case daysIndexes[2]:
          return "ср";

        case daysIndexes[3]:
          return "чт";

        case daysIndexes[4]:
          return "пт";

        case daysIndexes[5]:
          return "сб";

        case daysIndexes[6]:
          return "вс";

        default:
          return "Ежедневно";
      }
    });

    return translationWeekDays.length === 7
      ? "Ежедневно"
      : translationWeekDays.join(", ");
  };

  const handlePushRoute = async (flight) => {
    setSpinerLoader(flight.id);

    const departure = await API.getCities(
      airports[flight.dep_airport].code_iata
    ).then((result) => result[0]);
    const arrival = await API.getCities(
      airports[flight.arr_airport].code_iata
    ).then((result) => result[0]);

    const getDaysIndex = (days) => {
      return days.map((day) =>
        daysIndexes.findIndex((dayIndex) => dayIndex === day)
      );
    };

    const flightDays = getDaysIndex(JSON.parse(flight.days_of_week));

    const date = parseInt(
      flightDays.find((day) => day + 1 > moment().isoWeekday()) + 1 ||
        flightDays[0] + 1
    );
    const flightDate =
      date <= moment().isoWeekday()
        ? moment().add(1, "weeks").isoWeekday(date)
        : moment().isoWeekday(date);

    const currentRoutes = [...routes];
    currentRoutes[0] = {
      from: departure,
      to: arrival,
      date_from: flightDate.format("YYYY-MM-DD"),
    };
    currentRoutes[1].from = arrival;
    currentRoutes[1].to = departure;

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch(setRoutes(currentRoutes));

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setSpinerLoader(0);
  };

  const handleShowMore = () => {
    setShowMoreDestinations(12);
  };

  return (
    <Container fixed maxWidth="lg">
      <Header>
        <Icon src={departureArrival}/>
        {t.popular_destinations}
      </Header>
      <Destinations>
        <Airport>
          {t.from_airport}: {airports[from][`name_${language}`]}
        </Airport>
        <ItemWrapper>
          {flightsLimit.map((flight, index) => (
            <Figure key={flight.id} onClick={() => handlePushRoute(flight)}>
              <img src={`./citiesImages/${flight.arr_airport}.webp`} alt={flight.arr_airport} />
              <Figcaption>
                <div>
                  <span>
                    {
                      cities[airports[flight.arr_airport].city_code][
                        `name_${language}`
                      ]
                    }
                  </span>
                  <span>
                    {price(flight)} {price(flight) && currency}
                  </span>
                  <small>{dayOfTheWeeks(flight.days_of_week)}</small>
                </div>
              </Figcaption>
              {spinerLoader === flight.id && (
                <SpinerLoader>
                  <CircularProgress size={40} color="#fff" />
                </SpinerLoader>
              )}
            </Figure>
          ))}
        </ItemWrapper>
        {flights.length > 6 && showMoreDestinations === 6 && (
          <ShowMore onClick={handleShowMore}>{t.more_details}</ShowMore>
        )}
      </Destinations>
    </Container>
  );
};

export default PopularDestinations;
