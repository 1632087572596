import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import { DescriptionBlock, TitleBlock, Wrapper } from "./components";

const TourProgramComponent = ({ contents }) => {
  const { language, t } = useLocalization();

  return (
    <Wrapper>
      <TitleBlock>{t.tours_tour_program}</TitleBlock>

      <DescriptionBlock dangerouslySetInnerHTML={{ __html: contents.find(x => x.lang === language)?.content }} />
    </Wrapper>
  );
};

export default TourProgramComponent;
