import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.$isActive ? "#044476" : "#9eb3c7"};
  font-weight: bold;

  &:hover {
    color: #044476;
  }

  @media (max-width: 599px) {
    & > svg {
      display: none;
    }
  }
`;
