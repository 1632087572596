import React from "react";

import TourInfoComponent from "../TourInfoComponent";
import TourPriceComponent from "../TourPriceComponent";
import TourProgramComponent from "../TourProgramComponent";
import TourHotelComponent from "../TourHotelComponent";

import { StyledBlock, StyledFiltersBlock, StyledResultsBlock } from "./components";

const TourComponent = ({ tour }) => (
  <StyledBlock>
    <StyledResultsBlock>
      <TourInfoComponent
        name={tour.name}
        city={tour.city}
        description={tour.description}
        types={tour.types}
        images={tour.images}
      />
      <TourProgramComponent contents={tour.contents} />
      <TourHotelComponent hotel={tour.hotel} />
    </StyledResultsBlock>

    <StyledFiltersBlock>
      <TourPriceComponent id={tour.id} price={tour.price} />                    
    </StyledFiltersBlock>
  </StyledBlock>
);

export default TourComponent;
