import React from 'react';
import { useSelector } from 'react-redux';

import useLocalization from '../../../contexts/localization/hook';
import { Container, Block, LoadingText, LoadingCircularBlock } from './components';
import { ReactComponent as LoadingCircularIcon } from '../../../assets/icons/newDesign/loadingCircular.svg';
import SearchData from './parts/searchData';

const MainLoading = () => {
    const { searchLoading, searchData } = useSelector(state => state.loading);
    const { t } = useLocalization();

    return (
        <Container>
            <Block>
                {searchLoading && searchData ? <SearchData /> : <LoadingText>{t.wait_please}</LoadingText>}

                <LoadingCircularBlock>
                    <LoadingCircularIcon />
                </LoadingCircularBlock>
            </Block>
        </Container>
    );
};

export default MainLoading;
