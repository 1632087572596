import React, { useState } from "react";

import _ from "lodash";

import useLocalization from "../../../../contexts/localization/hook";

import { getFiltersData, sortByMaxPrice, sortByMinPrice } from "../../../../lib/tours";

import ToursFiltersComponent from "../../toursFilters/ToursFiltersComponent";

import ToursItemComponent from "../ToursItemComponent";
import ToursSortingTabComponent from "../ToursSortingTabComponent";

import { NotFoundOptionBlock, StyledBlock, StyledFiltersBlock, StyledResultsBlock, StyledToursBlock } from "./components";

const ToursComponent = ({ tours, included }) => {
  const { t } = useLocalization();

  const [filters, setFilters] = useState(tours.length ? getFiltersData(tours, included) : {});
  const [sorting, setSorting] = useState("new");

  const getFilteredTours = () => tours?.filter(tour => {
    if (tour.price < filters.price.selected.min || tour.price > filters.price.selected.max) return false;
    if (filters.cities.selected.length > 0 && !filters.cities.selected.includes(tour.city_id)) return false;
    if (filters.hotels.selected.length > 0 && !filters.hotels.selected.includes(tour.hotel_id)) return false;
    if (filters.types.selected.length > 0 && !!!filters.types.selected.find(type => tour.types.includes(type))) return false;

    return true;
  });

  const handleChangeFilters = (data) => {
    setFilters(prev => ({ ...prev, ...data }));
  };

  const filteredTours = getFilteredTours();

  const getSortedTours = () => {
    if (sorting === "new") return _.sortBy(filteredTours, "is_new").reverse();

    if (sorting === "lastMinute") return _.sortBy(filteredTours, "is_last_minute").reverse();

    if (sorting === "cheap") return sortByMinPrice(filteredTours);

    return sortByMaxPrice(filteredTours);
  };

  if (tours.length === 0) {
    return (
      <NotFoundOptionBlock>
        {t.tours_no_result}
      </NotFoundOptionBlock>
    );
  }

  return (
    <StyledBlock>
      <StyledFiltersBlock>
        <ToursFiltersComponent
          filters={filters}
          onChangeFilters={handleChangeFilters}
          sorting={sorting}
          onChangeSorting={setSorting}
        />
      </StyledFiltersBlock>

      <StyledResultsBlock>
        <ToursSortingTabComponent
          sorting={sorting}
          onChangeSorting={setSorting}
        />
        
        {!filteredTours.length ? (
            <NotFoundOptionBlock>
              {t.tours_no_results_by_filters}
            </NotFoundOptionBlock>
          ) : (                    
            <StyledToursBlock>
              {getSortedTours().map(tour => (
                <ToursItemComponent
                  key={tour.id}
                  tour={tour}
                  included={included}
                />
               ))}
              </StyledToursBlock>
          )
        }
      </StyledResultsBlock>
    </StyledBlock>
  );
};

export default ToursComponent;
