import styled from 'styled-components';

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #044476;
    border: 1px solid #53aafa;
    min-width: 50px;
    padding: 8px 12px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    height: 35px;
    margin-right: 16px;

    &:hover {
        cursor: pointer;
        border: 1px solid #044476;
    }

    @media (max-width: 959px) {
        display: block;
        font-size: 18px;
        line-height: 1.6;
        margin: ${props => (props.desktop && props.marginTop ? '16px' : '0')} 0 16px;
        border: none;
        padding: 0;

        &:hover {
            border: none;
        }
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    margin: 0 0 16px;
    position: relative;
`;

export const Label = styled.label`
    position: absolute;
    font-size: 12px;
    top: 8px;
    left: 16px;
    font-weight: bold;
    color: #404b5a;
`;

export const Input = styled.input`
    width: 100%;
    padding: 32px 16px 8px;
    font-size: 16px;
    border: 1px solid ${props => (props.error && props.touched ? '#ff0000' : '#c3d6e8')};
    border-radius: 12px;
`;

export const FormSubmitButton = styled.button`
    margin: 12px auto 0;
    background-color: #044476;
    border: none;
    border-radius: 12px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 12px 32px;
`;

export const ErrorText = styled.div`
    color: #ff0000;
    font-size: 14px;
    margin-top: 4px;
`;

export const NotFoundText = styled.div`
    color: #ff0000;
    font-size: 14px;
    margin-top: 4px;
    text-align: center;
`;
