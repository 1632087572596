import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        transform: translateY(0);
        opacity: 1;
    }
    
    to {
        transform: translateY(100%); 
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    display: ${props => (props.isShow ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 250;
    background-color: rgba(0, 0, 0, 0.7);

    @media (max-width: 959px) {
        background-color: transparent;
    }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 251;
    animation-name: ${props => (props.isShow ? fadeIn : fadeOut)};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 90%;
    width: ${props => (props.fixedWidth ? `${props.maxWidth}px` : 'auto')};
    max-width: ${props => props.maxWidth}px;
    min-width: 360px;
    position: relative;
    border-radius: 8px;

    @media (max-width: 959px) {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        border-radius: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    @media (max-width: 959px) {
        justify-content: center;
        height: 50px;
        min-height: 50px;
        border-bottom: 1px solid #eee;
        padding: 0;
    }
`;

export const Title = styled.div`
    font-weight: bold;
    font-size: 18px;
    color: #404b5a;

    @media (max-width: 959px) {
        text-align: center;
        max-width: 80%;
        text-overflow: ellipsis;
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute !important;
    right: 12px;
    top: 10px;
`;

export const CloseIcon = styled(Close)``;

export const Body = styled.div`
    padding: 0 16px 16px;
    overflow-y: auto;

    @media (max-width: 959px) {
        padding: 16px;
        min-height: calc(100% - 50px);
    }
`;

export const LeftButton = styled.button`
    background-color: transparent;
    border: none;
    color: #044476;
    position: absolute !important;
    left: 8px;
    top: 8px;
    font-size: 16px;
    padding: 8px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
`;
