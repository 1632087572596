import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Component,
    HideOnMobile,
    HideOnPc,
    Block,
    MainBlock,
    Column,
    LogoBlock,
    ContactUsText,
    ContactsEmail,
    ContactPhone,
    LinkToDownload,
    LinkToStaticPage,
    SocialsBlock,
    SocialsLinks,
    SocialLink,
    DontCopyText,
    PaymentMethods,
    PaymentMethodsText,
    PaymentMethodsLogos,
    PaymentMethodLogo,
    OsmonWhiteLogo,
    FeedbackModalLink,
} from './components';
import useLocalization from '../../../contexts/localization/hook';
import { Telegram as TelegramIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@material-ui/icons';
import OsmonLogo from '../../../assets/icons/newDesign/flytrip.png';
import telegramIcom from "../../../assets/icons/newDesign/telegram-icon-connect.svg"
// import clickUzCard from '../../../images/otherLogos/clickUzCard.jpg';
// import paymeCard from '../../../images/otherLogos/payme.png';
// import uzCard from '../../../images/otherLogos/uzcardLogo.jpg';
import alifPay from '../../../images/otherLogos/alif-pay.png';
import peymentCard from "../../../images/otherLogos/debit-cart.svg"
import API from '../../../lib/api';
import envConfigs from '../../../config/envConfigs';
import SubscriptionToNewsletter from '../main/parts/subscriptionToNewsletter';
import { CircularProgress } from '@material-ui/core';
import { Button, FormGroup, Label, FormSubmitButton, Input, ErrorText, NotFoundText } from './components';
import ModalComponent from '../ui/modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const Footer = () => {
    const { t, language } = useLocalization();
    const [state, setState] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setModalActive(false);
    };

    useEffect(() => {
        staticInfoDiscriptions();
    }, []);

    const staticInfoDiscriptions = async () => {
        await API.getStaticDiscriptions().then(res => {
            setState(res.data);
        });
    };

    const divideBlocks = (array, chunks) => {
        let result = [];
        let n = state.length;

        for (let i = 0; i < n; i += chunks) {
            result = [...result, array.slice(i, i + chunks)];
        }

        return result;
    };

    const handleFeedback = values => {
        setIsLoading(true);

        API.storeFeedback(values).then(response => {
            if (response.msg) {
                handleClose();
                resetForm();
            }

            setIsLoading(false);
        });
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
        text: Yup.string().trim().required(t.required),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues: {
            email: '',
            text: '',
        },
        validationSchema,
        onSubmit: handleFeedback,
        validateOnBlur: true,
    });

    /*const handleKeyPress = e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleSubmit();
        }
    };*/

    let columns = divideBlocks(state, 6);

    return (
        <Component>
            <Container fixed maxWidth="lg">
                <Block>
                    <MainBlock>
                        <Column>
                            <HideOnMobile>
                                <LogoBlock>
                                    <OsmonWhiteLogo src={OsmonLogo} />
                                </LogoBlock>
                            </HideOnMobile>

                            <ContactUsText>{t.contact_us}:</ContactUsText>
                            <ContactsEmail>
                                {t.customer_support}: <a href={`mailto:${envConfigs.supportMail}`}>{envConfigs.supportMail}</a>
                            </ContactsEmail>
                            <ContactPhone>
                            <a href="https://t.me/+998908309668" rel="noopener noreferrer" target='_blank'><img className='social-icon' src={telegramIcom} alt="" /></a> Сабрина: <a href={`tel:+998908309668`}>+998 90 830 96 68</a>
                            </ContactPhone>
                            <ContactPhone>
                            <a href="https://t.me/+998908304064" rel="noopener noreferrer" target='_blank'><img className='social-icon' src={telegramIcom} alt="" /></a> Виктория: <a href={`tel:+998908304064`}>+998 90 830 40 64</a>
                            </ContactPhone>
                        </Column>

                        {columns.map((items, key) => {
                            return (
                                <Column key={`footer-list${key}`}>
                                    {key === 0 && (
                                        <LinkToStaticPage to={`/useful-info`}>
                                            {t.useful_info || 'Полезная информация'}
                                        </LinkToStaticPage>
                                    )}

                                    {items.map((item, index) => {
                                        const contentByLanguage = item.footer_content.find(
                                            content => content.language === language
                                        );

                                        if (contentByLanguage?.attachment) {
                                            return (
                                                <LinkToDownload
                                                    href={`${envConfigs.uploadHost}${contentByLanguage?.attachment}`}
                                                    key={`list-${index}`}
                                                >
                                                    {contentByLanguage?.page_name}
                                                </LinkToDownload>
                                            );
                                        }

                                        return (
                                            <LinkToStaticPage to={`/staticInfoPage/${item.slug}`} key={`list-${index}`}>
                                                {contentByLanguage?.page_name}
                                            </LinkToStaticPage>
                                        );
                                    })}
                                    <FeedbackModalLink onClick={() => setModalActive(true)}>
                                        Напишите нам
                                    </FeedbackModalLink>

                                    <ModalComponent
                                        active={modalActive}
                                        onClose={handleClose}
                                        title="Оставить сообщение"
                                        maxWidth={600}
                                        fixedWidth
                                    >
                                        <FormGroup>
                                            <Label>Ваш Email *</Label>
                                            <Input
                                                value={values.email}
                                                onChange={handleChange('email')}
                                                type="email"
                                                required
                                                onBlur={handleBlur('email')}
                                                placeholder="Email"
                                                touched={touched.email}
                                                error={errors.email}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Ваше Сообщение *</Label>
                                            <Input
                                                value={values.text}
                                                onChange={handleChange('text')}
                                                type="text"
                                                required
                                                placeholder="Сообщение"
                                                onBlur={handleBlur('text')}
                                                touched={touched.text}
                                                error={errors.text}
                                            />
                                        </FormGroup>
                                        <FormSubmitButton type="button" onClick={handleSubmit}>
                                            {isLoading ? <CircularProgress color="inherit" size={20} /> : `${t.send}`}
                                        </FormSubmitButton>
                                    </ModalComponent>
                                </Column>
                            );
                        })}

                        <Column>
                            <PaymentMethodsText>{t.payment_methods || 'Способы оплаты'}:</PaymentMethodsText> <br />
                            <PaymentMethods>
                                <PaymentMethodsLogos>
                                    <PaymentMethodLogo src={peymentCard} />
                                </PaymentMethodsLogos>
                            </PaymentMethods>
                            <SubscriptionToNewsletter />
                        </Column>
                    </MainBlock>

                    <SocialsBlock>
                        <HideOnPc>
                            <LogoBlock>
                                <OsmonWhiteLogo src={OsmonLogo} />
                            </LogoBlock>
                        </HideOnPc>

                        <SocialsLinks>
                            {envConfigs.facebookLink && (
                                <SocialLink target="_blank" href={envConfigs.facebookLink}>
                                    <FacebookIcon />
                                </SocialLink>
                            )}

                            {envConfigs.instagramLink && (
                                <SocialLink target="_blank" href={envConfigs.instagramLink}>
                                    <InstagramIcon />
                                </SocialLink>
                            )}

                            {envConfigs.telegramLink && (
                                <SocialLink target="_blank" href={envConfigs.telegramLink}>
                                    <TelegramIcon style={{ color: '#229ED9' }} />
                                </SocialLink>
                            )}
                        </SocialsLinks>
                    </SocialsBlock>

                    <DontCopyText>powered by Novasoft.</DontCopyText>
                </Block>
            </Container>
        </Component>
    );
};

export default Footer;
