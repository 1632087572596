import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import FilterBlock from "../../resultPage/filters/parts/filterBlock";

import { Block, Checkbox, Row, Text } from "./components";

const ToursFilterSortingComponent = ({ sorting, onChangeSorting }) => {
  const { t } = useLocalization();

  const sortingTypes = [
    { value: "new", label: t.tours_new },
    { value: "lastMinute", label: t.tours_last_minute },
    { value: "cheap", label: t.tours_cheap_first },
    { value: "expensive", label: t.tours_expensive_first },
  ];

  const handleOnClick = (value) => onChangeSorting(value);

  return (
    <FilterBlock title={t.sorting} isOpen borderBottom>
      <Block>
        {sortingTypes.map(type => (
          <Row key={`${type.value}`} onClick={() => handleOnClick(type.value)}>
            <Checkbox className={type.value === sorting && 'active'} />
            <Text className={type.value === sorting && 'active'}>{type.label}</Text>
          </Row>
        ))}
      </Block>
    </FilterBlock>
  );
};

export default ToursFilterSortingComponent;
