import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';

export const AccordionComponent = styled(Accordion)`
    box-shadow: none !important;
    background-color: transparent !important;
    margin-top: 0px !important;

    &::before {
        content: none !important;
    }
`;

export const AccordionHeader = styled(AccordionSummary)`
    padding: 8px 0 !important;
    font-size: 20px;
    font-weight: bold;
    color: #404b5a !important;
    min-height: auto !important;
    opacity: 1 !important;

    & > .MuiAccordionSummary-content {
        margin: 12px 0 !important;
    }
`;

export const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const HeaderButton = styled.button`
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    color: #044476;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        color: #9da8ae;

        &:hover {
            cursor: default;
        }
    }

    &:nth-child(1) {
        margin-right: 8px;
    }

    &:nth-child(2) {
        margin-left: 8px;
    }
`;

export const AccordionBody = styled(AccordionDetails)`
    width: 100%;
    padding: 0 0 16px !important;
`;

export const CheckIcon = styled(CheckCircleOutline)`
    margin-left: 8px;
    color: #3bb30a !important;
`;
