import React, { useCallback, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    Navigation,
    NavigationLink,
    Form,
    FormTitle,
    FormSubtitle,
} from '../../components/design_v2/authPages/components';

import { nonAuthRoutes } from '../../config/routes';
import API from '../../lib/api';
import { setClientData } from '../../store/clientData/actions';
import { setAppConfigs } from '../../store/appConfigs/actions';
import useLocalization from '../../contexts/localization/hook';
import RegistrationForm from './parts/registrationForm';
import ActivationForm from './parts/activationForm';

const NewLoginPage = () => {
    const { activeForm } = useSelector(store => store.registration);
    const { isFullScreenSearchBlock } = useSelector(state => state.appConfigs);

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useLocalization();
    const containerRef = useRef();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, [isFullScreenSearchBlock]);

    const validationSchema = Yup.object().shape({
        login: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
        first_name: Yup.string().trim().required(t.required),
        last_name: Yup.string().trim().required(t.required),
        father_name: Yup.string().trim(),
    });

    const handleFormSubmit = useCallback(
        values => {
            if (isLoading) {
                return;
            }

            setIsLoading(true);

            API.login(values)
                .then(response => {
                    dispatch(
                        setClientData({
                            firstName: response.data.first_name,
                            lastName: response.data.last_name,
                            fatherName: response.data.father_name,
                            login: response.data.login,
                            currency: response.data.currency,
                        })
                    );

                    dispatch(
                        setAppConfigs({
                            isAuth: true,
                        })
                    );

                    window.localStorage.setItem('token', response.data.token);

                    history.push('/');
                })
                .catch(error => {
                    setFieldError('login', error.response.data.message);
                    setIsLoading(false);
                });
        },
        [isLoading]
    );

    const { setFieldError } = useFormik({
        initialValues: {
            login: '',
            first_name: '',
            last_name: '',
            father_name: '',
        },
        validationSchema,
        onSubmit: handleFormSubmit,
        validateOnBlur: true,
    });

    return (
        <Container fixed ref={containerRef}>
            <Navigation>
                <NavigationLink to={nonAuthRoutes.login}>{t.log_in}</NavigationLink>

                <NavigationLink active={true} to={nonAuthRoutes.registration}>
                    {t.registration}
                </NavigationLink>
            </Navigation>

            <Form>
                <FormTitle>{activeForm == 'registration' ? t.registration : t.activation_title}</FormTitle>

                <FormSubtitle>{activeForm == 'registration' ? t.register_to_save_data : t.activation_code_send_to_email}</FormSubtitle>

                {activeForm === 'registration' && <RegistrationForm />}

                {activeForm === 'activation' && <ActivationForm />}
            </Form>
        </Container>
    );
};

export default withRouter(NewLoginPage);
