import React, { useEffect, useRef, useState } from "react";

import useLocalization from "../../../../contexts/localization/hook";

import {
  Backdrop,
  Button,
  ButtonBlock,
  CloseButton,
  Modal,
  StyledCloseIcon,
  Text,
  Wrapper,
} from "./components";
import { useSelector } from "react-redux";

const LimitErrorModal = ({ active }) => {
  const backdropRef = useRef(null);
  const { t } = useLocalization();

  const { isAuth } = useSelector((state) => state.appConfigs);

  const [showModal, setShowModal] = useState(active);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("hidden");
    }
  }, [showModal]);

  const handleClose = (type) => {
    document.body.classList.remove("hidden");

    if (type === "link") {
      setShowModal(false);
    } else {
      window.location.href = "/";
    }
  };

  const handleBackdropClick = (event) => {
    if (event.target === backdropRef.current) {
      handleClose();
    }
  };

  return (
    <Wrapper isShow={showModal}>
      <Backdrop
        isShow={showModal}
        onClick={handleBackdropClick}
        ref={backdropRef}
      >
        <Modal isShow={showModal}>
          <CloseButton onClick={handleClose} size="small">
            <StyledCloseIcon />
          </CloseButton>

          <Text>
            {isAuth
              ? t.search_limits_exceeded_for_authorized_user
              : t.search_limits_exceeded}
          </Text>

          {!isAuth && (
            <ButtonBlock>
              <Button to="/login" onClick={() => handleClose("link")}>
                {t.to_login_form}
              </Button>
            </ButtonBlock>
          )}
        </Modal>
      </Backdrop>
    </Wrapper>
  );
};

export default LimitErrorModal;
