import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
`;

export const TitleBlock = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #404b5a;
`;

export const CityBlock = styled.div`
  font-size: 14px;
  color: #9eb3c7;
  margin-bottom: 16px;
`;

export const DescriptionBlock = styled.div`
  color: #404b5a;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const TypesBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TypeBlock = styled.div`
  border: 1px solid #4ea7bf;
  color: #4ea7bf;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 4px;
`;
