import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useLocalization from '../../../../../../contexts/localization/hook';
import locales from './localization';

import { Container, TextsBlock, PassengerText, ClassText, DropdownBlock } from './components';
import PassengersAndClassDropdown from '../../passengersAndClassDropdown';
import ModalComponent from '../../../../ui/modal';
import PassengersAndClassMobile from '../../passengersAndClassMobile';

const PassengersAndClassField = props => {
    const [isActive, setIsActive] = useState(false);
    const [mobileMenuIsActive, setMobileMenuIsActive] = useState(false);
    const componentBlock = useRef();
    const dropdownBlock = useRef();
    const { passengers, cabin, flightType } = useSelector(state => state.searchParams);
    const { t, language } = useLocalization();
    const { pWidth, dWidth } = props;

    const cabins = {
        economy: t.economy,
        business: t.business,
        first: t.first,
        all: t.all_class,
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleClick = e => {
        if (dropdownBlock.current.contains(e.target) || componentBlock.current.contains(e.target)) {
            return;
        }

        setIsActive(false);
    };

    const handleContainerClick = () => {
        if (window.innerWidth < 960) {
            setMobileMenuIsActive(true);
        } else {
            setIsActive(!isActive);
        }
    };

    const getPassengersText = () => {
        const sum = passengers.adt + passengers.chd + passengers.ins + passengers.inf;

        if (sum === 1) {
            return `${sum} ${locales[language]?.one}`;
        }

        if (sum > 1 && sum < 5) {
            return `${sum} ${locales[language]?.few}`;
        }

        if (sum >= 5) {
            return `${sum} ${locales[language]?.more}`;
        }
    };

    return (
        <>
            <Container
                isActive={isActive}
                onClick={handleContainerClick}
                ref={componentBlock}
                className={isActive && 'active'}
                isSimple={flightType !== 'CF'}
                pWidth={pWidth}
            >
                <TextsBlock>
                    <PassengerText>{getPassengersText()}</PassengerText>
                    <ClassText>{cabins[cabin]}</ClassText>
                </TextsBlock>
            </Container>

            <ModalComponent
                active={mobileMenuIsActive}
                onClose={() => setMobileMenuIsActive(false)}
                title={t.passengers}
            >
                <PassengersAndClassMobile onClose={() => setMobileMenuIsActive(false)} />
            </ModalComponent>

            <DropdownBlock ref={dropdownBlock}>
                {isActive && <PassengersAndClassDropdown dWidth={dWidth} />}
            </DropdownBlock>
        </>
    );
};

export default PassengersAndClassField;
