import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { Container, Grid, Checkbox } from '@material-ui/core';

import { useStyles } from '../../containers/booking/styles';
import { ADT, CHD, INS, INF } from '../../containers/search/constants';
import useLocalization from '../../contexts/localization/hook';
import CovidRules from './covidRules';
import RulesModal from './rulesModal';
import { PassengerItem, PayerBlock, Submit } from './passengersInfoForm';

const PassengerInfoForm = props => {
    const classes = useStyles();
    const { t } = useLocalization();
    const {
        passengers,
        onUpdateCitizenship,
        getCountries,
        resetCountries,
        onSendBooking,
        handleSubmit,
        setPassengerType,
        countries,
        formValues,
        clearDocumentData,
        deadlineDate,
        formErrors,
        setSelectedPassengerIndex,
        setOpenSuccessModal,
        setPayerEmailToPassenger,
        setPayerPhoneToPassenger,
        routes,
        fareRules,
        included,
        onSelectAvailableDocumentTypes,
    } = props;

    const { login, phone } = useSelector(state => state.clientData);

    const [checked, setChecked] = useState(false);
    const [openRules, setOpenRules] = useState(false);

    useEffect(() => {
        _.forEach(passengersList, function (type, index) {
            setPassengerType(type, index);
        });

        setPayerEmailToPassenger(login);
        setPayerPhoneToPassenger(phone);
    }, []);

    const getPassengerList = items => {
        let passengersArray = [];
        let chdArray = [];
        let insArray = [];

        _.forEach(items, function (count, passengerType) {
            if (passengerType === ADT) {
                _.times(count, () => {
                    passengersArray.push([passengerType]);
                });
            }

            if (passengerType === CHD) {
                _.times(count, () => {
                    chdArray.push(passengerType);
                });
            }

            if (passengerType === INS) {
                _.times(count, () => {
                    insArray.push(passengerType);
                });
            }

            if (passengerType === INF) {
                _.times(count, index => {
                    passengersArray[index] = [...passengersArray[index], passengerType];
                });
            }
        });

        return [..._.flatten(passengersArray), ...chdArray, ...insArray];
    };

    const passengersList = getPassengerList(passengers);

    const handleCloseRules = () => {
        setOpenRules(false);
    };

    const renderPassengers = () =>
        _.map(passengersList, (type, index) => {
            return (
                <PassengerItem
                    key={index}
                    passengerType={type}
                    index={index}
                    setOpenSuccessModal={setOpenSuccessModal}
                    formErrors={formErrors}
                    onUpdateCitizenship={onUpdateCitizenship}
                    getCountries={getCountries}
                    resetCountries={resetCountries}
                    countries={countries}
                    passengersData={passengersList}
                    formValues={formValues}
                    deadlineDate={deadlineDate}
                    clearDocumentData={clearDocumentData}
                    setSelectedPassengerIndex={setSelectedPassengerIndex}
                    onSelectAvailableDocumentTypes={onSelectAvailableDocumentTypes}
                />
            );
        });

    return (
        <form onSubmit={handleSubmit(data => onSendBooking(data))}>
            <Grid container spacing={0} className={classes.passengersContainer}>
                <div className={classes.passengersBlock}>
                    <PayerBlock
                        setPayerEmailToPassenger={setPayerEmailToPassenger}
                        setPayerPhoneToPassenger={setPayerPhoneToPassenger}
                    />

                    <Grid item className={classes.passengersTitle}>
                        {passengersList.length === 1 ? t.passenger_data : t.passengers}
                    </Grid>

                    {renderPassengers()}
                </div>

                <RulesModal
                    openRules={openRules}
                    handleCloseRules={handleCloseRules}
                    fareRules={fareRules}
                    routes={routes}
                    included={included}
                />

                <div className={classes.agreementTheBook}>
                    <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        disableRipple
                        style={{ padding: '0 8px 0 0' }}
                    />
                    <div>
                        {t.i_read_fares}
                        <span className={classes.agreementBookLink} onClick={() => setOpenRules(true)}>
                            {t.agreement_book_link}
                        </span>
                        {t.i_accept_fares}
                    </div>
                </div>

                <Container fixed className={classes.containerCovid}>
                    <CovidRules />
                </Container>

                <Submit routes={routes} fareRules={fareRules} included={included} disabled={checked} />
            </Grid>
        </form>
    );
};

let form = reduxForm({ form: 'booking' })(PassengerInfoForm);

form = connect(state => ({
    initialValues: {
        payerEmail: state.clientData.login,
        payerPhone: state.clientData.phone,
    },
}))(form);

export default form;
