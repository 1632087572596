import React, { useState, useRef, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import UserEditProfile from '../userEdit';
import { FormSubmitButton } from '../../components/design_v2/authPages/components';
import { ButtonContainer, TitleContainer, ID } from './components';
import { Title, Items, Item, Label, Value } from '../../components/design_v2/userProfile/components';
import useLocalization from '../../contexts/localization/hook';

const NewUserProfile = () => {
    const { firstName, lastName, fatherName, login, balance, currency, email, phone, id } = useSelector(
        state => state.clientData
    );

    const { t } = useLocalization();

    const containerRef = useRef();

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: containerRef.current.offsetTop - 100 });
    }, []);

    return (
        <Container fixed ref={containerRef}>
            <TitleContainer>
                <Title>{t.personal_data}</Title>
                <ID>
                    ({t.your_id}:{id})
                </ID>
            </TitleContainer>
            {edit ? (
                <UserEditProfile toggleEdit={() => setEdit(false)} balance={`${balance} ${currency}`} />
            ) : (
                <Items>
                    <Item>
                        <Label>{t.last_name}</Label>
                        <Value>{lastName}</Value>
                    </Item>

                    <Item>
                        <Label>{t.fist_name}</Label>
                        <Value>{firstName}</Value>
                    </Item>

                    <Item>
                        <Label>{t.father_name}</Label>
                        <Value>{fatherName || '-'}</Value>
                    </Item>

                    <Item>
                        <Label>{t.login_text}</Label>
                        <Value>{login}</Value>
                    </Item>

                    <Item>
                        <Label>{t.email}</Label>
                        <Value>{email || '-'}</Value>
                    </Item>

                    <Item>
                        <Label>{t.phone_number}</Label>
                        <Value>{phone || '-'}</Value>
                    </Item>

                    <Item>
                        <Label>{t.balance}</Label>
                        <Value>
                            {balance} {currency}
                        </Value>
                    </Item>
                    {!edit && (
                        <ButtonContainer>
                            <FormSubmitButton onClick={() => setEdit(!edit)} heightAdd="50">
                                {t.edit}
                            </FormSubmitButton>
                        </ButtonContainer>
                    )}
                </Items>
            )}
        </Container>
    );
};

export default NewUserProfile;
