import React, { useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import config from "../../../../config";

import API from "../../../../lib/api";

import useLocalization from "../../../../contexts/localization/hook";

import MaskedTextInput from "../../ui/maskedTextInput";
import ModalComponent from "../../ui/modal";
import TextInput from '../../ui/textInput';

import { Form, FormGroup, FormSubmitButton, FormSubtitle, FormTitle } from "./components";

const TourOrderDialogComponent = ({ isShow, onClose, id }) => {
  const { t } = useLocalization();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessResponse, setIsSuccessResponse] = useState(false);

  const validationSchema = Yup.object().shape({
    user_email: Yup.string().trim().email(t.enter_valid_mail).required(t.required),
    user_phone: Yup.string()
      .required(t.required)
      .test('validate-phone', t.invalid_phone, value => {
        const trimmedValue = value?.replace(/[^0-9.]+/g, '');
        return trimmedValue?.length > 10;
      }),
    user_name: Yup.string().trim().min(2, t.min_2_symbol).required(t.required),
  });

  const handleFormSubmit = values => {
    if (isLoading) return;

    setIsLoading(true);

    API.orderForTour({ ...values, tour_id: id })
      .then(response => {
        setIsSuccessResponse(true);
      })
      .catch(error => {
        setFieldError('user_email', error.response.data.message);
        setIsLoading(false);
      });
    };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldError } = useFormik(
    {
      initialValues: {
      user_email: '',
      user_phone: '',
      user_name: '',
      },
      validationSchema,
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
    }
  );

  const handlePhoneChange = value => {
    const trimmedValue = value.replace(/[^0-9.]+/g, '');

    setFieldValue('user_phone', trimmedValue);
  };

  return (
    <ModalComponent
      active={isShow}
      onClose={onClose}
      maxWidth={600}
      fixedWidth
    >
      {isSuccessResponse ? (
          <Form>
            <FormTitle>{t.tours_request_success}!</FormTitle>

            <FormSubtitle>{t.tours_request_success_description}</FormSubtitle>

            <FormSubmitButton type="button" onClick={onClose}>{t.close}</FormSubmitButton>
          </Form>
        ) : (
          <Form>
            <FormTitle>{t.tours_create_request}</FormTitle>
    
            <FormSubtitle>{t.tours_leave_contacts}</FormSubtitle>
    
            <FormGroup error={false}>
              <TextInput
                value={values.user_name}
                onChangeText={handleChange("user_name")}
                placeholder={t.fist_name}
                label={t.fist_name}
                type="text"
                required
                onBlur={handleBlur("user_name")}
                error={errors.user_name}
                touched={touched.user_name}
              />
            </FormGroup>
    
            <FormGroup error={false}>
              <TextInput
                value={values.user_email}
                onChangeText={handleChange("user_email")}
                type="email"
                required
                placeholder="Email"
                label="Email"
                error={errors.user_email}
                onBlur={handleBlur("user_email")}
                touched={touched.user_email}
              />
            </FormGroup>
    
            <FormGroup error={false}>
              <MaskedTextInput
                value={values.user_phone}
                onChangeText={handlePhoneChange}
                type="text"
                required
                placeholder={t.phone_number}
                label={t.phone_number}
                error={errors.user_phone}
                onBlur={handleBlur("user_phone")}
                touched={touched.user_phone}
                mask={config.envConfigs.phoneMask}
              />
            </FormGroup>
    
            <FormSubmitButton type="submit" onClick={handleSubmit} marginAdd>
              {isLoading ? <CircularProgress color="inherit" size={26} /> : t.tours_order}
            </FormSubmitButton>
          </Form>
        )
      }
    </ModalComponent>
  );
};

export default TourOrderDialogComponent;
