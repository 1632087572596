import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useLocalization from '../../../../../../../../contexts/localization/hook';
import { setCurrency } from '../../../../../../../../store/appConfigs/actions';
import defaultCurrencies from '../../../../../../../../config/defaultCurrencies';
import defaultLanguages from '../../../../../../../../config/defaultLanguages';

import {
    Wrapper,
    Button,
    SettingsIcon,
    GroupTitle,
    Items,
    Item,
    Code,
    Label,
    CheckboxBlock,
    Checkbox,
} from './components';
import ModalComponent from '../../../../../../ui/modal';

const SettingsMenu = () => {
    const { availableCurrencies, currency } = useSelector(state => state.appConfigs);
    const dispatch = useDispatch();
    const { t, language, availableLanguages, switchLang } = useLocalization();

    const [isActive, setIsActive] = useState(false);

    const handleSelectCurrency = useCallback(currency => {
        localStorage.setItem('currency', currency);
        setIsActive(false);
        dispatch(setCurrency(currency));
    }, []);

    const handleSelectLanguage = useCallback(language => {
        setIsActive(false);
        switchLang(language);
    }, []);

    const renderCurrencies = useMemo(() => {
        return availableCurrencies.map(value => {
            return (
                <Item key={`currency-${value}`} onClick={() => handleSelectCurrency(value)}>
                    <Code>{value}</Code>

                    <Label className={value === currency && 'active'}>{defaultCurrencies[language][value]}</Label>

                    <CheckboxBlock>
                        <Checkbox className={value === currency && 'active'} />
                    </CheckboxBlock>
                </Item>
            );
        });
    }, [availableCurrencies, currency, language]);

    const renderLanguages = useMemo(() => {
        return availableLanguages.map(value => {
            return (
                <Item key={`languages-${value}`} onClick={() => handleSelectLanguage(value)}>
                    <Code>{value}</Code>

                    <Label className={value === language && 'active'}>{defaultLanguages[language][value]}</Label>

                    <CheckboxBlock>
                        <Checkbox className={value === language && 'active'} />
                    </CheckboxBlock>
                </Item>
            );
        });
    }, [availableLanguages, language]);

    return (
        <Wrapper>
            <Button onClick={() => setIsActive(true)}>
                <SettingsIcon />
            </Button>

            <ModalComponent active={isActive} onClose={() => setIsActive(false)} title={t.settings}>
                <GroupTitle>{t.currency}</GroupTitle>
                <Items>{renderCurrencies}</Items>

                <GroupTitle>{t.language}</GroupTitle>
                <Items>{renderLanguages}</Items>
            </ModalComponent>
        </Wrapper>
    );
};

export default SettingsMenu;
