import React, { useEffect, useState } from "react";

import { Container } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { withRouter } from 'react-router';

import envConfigs from "../../config/envConfigs";

import API from "../../lib/api";

import { setAppConfigs } from "../../store/appConfigs/actions";

import useLocalization from "../../contexts/localization/hook";

import Loading from "../../components/design_v2/ui/loading";
import ToursComponent from "../../components/design_v2/tours/ToursComponent";

const ToursPage = () => {
  const dispatch = useDispatch();
  const { t } = useLocalization();

  const [tours, setTours] = useState([]);
  const [included, setIncluded] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = `${t.tours} | ${envConfigs.appTitle}`;

    dispatch(setAppConfigs({ showSearchBlock: false }));

    API.getTours().then(response => {
      setTours(response.data);
      setIncluded(response.included);
      setIsLoading(false);
    });

    return () => {
      dispatch(setAppConfigs({ showSearchBlock: true }));
    };
  }, [t]);

  return (
    <Container fixed>
      {isLoading ? <Loading /> : (
        <ToursComponent tours={tours} included={included} />
      )}
    </Container>
  );
};

export default withRouter(ToursPage);
