import React, { Fragment, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import useLocalization from '../../contexts/localization/hook';

const useStyles = makeStyles({
    contentContainer: {
        fontSize: 16,
    },
    titleBlock: {
        paddingLeft: '44px',
        paddingBottom: 0,
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        cursor: 'pointer',
        right: '3%',
    },
    cancelButton: {
        height: '40px',
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: '#fafbfc',
        '&:active': {
            backgroundColor: '#cfd8dc',
        },
        '&:disabled': {
            backgroundColor: '#f2f4f5',
            color: '#b9c1c5',
        },
    },
    buttonBlock: {
        paddingBottom: '8px',
        paddingRight: '24px',
    },
    buttonTitle: {
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    p1: {
        textIndent: '1.5em',
        marginBottom: 0,
    },
    p2: {
        textIndent: '1.5em',
        marginTop: 0,
    },
    p3: {
        textIndent: '1.5em',
        marginTop: 0,
        marginBottom: 0,
    },
    linkTitle: {
        fontSize: 12,
        color: '#84b6df',
        cursor: 'pointer',
    },
});

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function UnitellerSecurityInfo() {
    const classes = useStyles();
    const { t } = useLocalization();

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleClickOpen = () => {
        setIsOpen(true);
    };

    return (
        <Fragment>
            <div onClick={handleClickOpen} className={classes.linkTitle}>
                {t.security_guarantees_unitaller}
            </div>
            <Dialog open={isOpen} onClose={handleClose} PaperComponent={PaperComponent} maxWidth="md">
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className={classes.titleBlock}>
                    <span>{t.security_guarantees_unitaller}</span>
                    <CloseIcon className={classes.closeButton} onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                    <div className={classes.contentContainer}>
                        <MessageData />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={classes.buttonBlock}>
                        <Button onClick={handleClose} disableRipple className={classes.cancelButton}>
                            <div className={classes.buttonTitle}>{t.close}</div>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

const MessageData = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <p className={classes.p1}>Уважаемый клиент!</p>
            <p className={classes.p2}>
                Вы можете оплатить свой заказ онлайнс помощью предложенных методов оплат через платежный сервис компании{' '}
                <Link target="_blank" href="https://www.uniteller.ru/">
                    Uniteller
                </Link>
                . После подтверждения заказа Вы будете перенаправлены на защищенную платежную страницу Uniteller, где
                необходимо будет ввести данные для оплаты заказа. После успешной оплаты на указанную в форме оплаты
                электронную почту будет направлен электронный чекс информациейо заказе и данными по произведенной
                оплате.
            </p>
            <p className={classes.p1}>
                <b>Гарантии безопасности</b>
            </p>
            <p className={classes.p3}>
                Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных индустрии
                платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга
                мошеннических операций, а также применением 3D Secure-современной технологией безопасности
                интернет-платежей.
            </p>
            <p className={classes.p3}>
                Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в{' '}
                <Link target="_blank" href="https://www.uniteller.ru/services/solutions/">
                    процессинговую компанию
                </Link>{' '}
                Uniteller происходит с применением технологии шифрования TLS. Дальнейшая передача информации
                осуществляетсяпо закрытым банковским каналам, имеющим наивысший уровень надежности.
            </p>
            <p className={classes.p3}>
                <b>
                    <i>Uniteller не передает данные Вашей карты магазину и иным третьим лицам!</i>
                </b>
            </p>
            <p className={classes.p3}>
                Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет
                пройти дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для
                этого Вы будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как
                правило, это дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие
                способы.
            </p>
            <p className={classes.p3}>
                Если у Вас возникли вопросы по совершенному платежу, Вы можете обратиться в службу технической поддержки
                процессингового центра Uniteller: <Link href="mailto:support@uniteller.ru">support@uniteller.ru</Link>{' '}
                или по телефону <b>8 800 100 19 60</b>.
            </p>
        </Fragment>
    );
};
