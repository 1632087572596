import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#404b5a',
    paddingTop: 30,
});

export const Icon = styled('img')({
    '@media (max-width: 959px)': {
        display: 'none',
    }
});

export const ShowMoreButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
`;

export const ShowMoreButton = styled(NavLink)`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid #044476;
    border-radius: 24px;
    background-color: transparent;
    height: 35px;
    color: #044476;
    cursor: pointer;
    margin-bottom: 32px;
    padding: 0 16px;
    text-decoration: none;
`;
