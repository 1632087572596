import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocalization from '../../../../../../contexts/localization/hook';
import { setFilterParams } from '../../../../../../store/filters/actions';
import RangeSlider from '../rangeSlider';
import FilterBlock from '../filterBlock';
import { RouteBlock, RouteCities } from './components';

const DepartureTimeFilter = () => {
    const { t, language } = useLocalization();
    const dispatch = useDispatch();
    const { filterParams, filtersData } = useSelector(state => state.filters);
    const { included } = useSelector(state => state.resultData);
    const { isActive, values } = filterParams.departureTime;
    const { routesData } = filtersData;

    const handleChange = (routeIndex, newValues) => {
        let params = [...values];

        if (newValues.min !== 0 || newValues.max !== 86400) {
            const index = params.findIndex(param => param.routeIndex === routeIndex);

            if (index > -1) {
                params[index].values = newValues;
            } else {
                params.push({ routeIndex, values: newValues });
            }
        } else {
            params = params.filter(param => param.routeIndex !== routeIndex);
        }

        dispatch(
            setFilterParams({
                type: 'departureTime',
                values: {
                    isActive: params.length > 0,
                    values: params,
                },
            })
        );
    };

    const getRangeTime = (min, max, startOfDay) => {
        const format = 'HH:mm';
        const start = startOfDay.clone().add(min, 'seconds').format(format);
        const end = startOfDay.clone().add(max, 'seconds').format(format);

        return [start, end];
    };

    const TitleComponent = ({ min, max, startOfDay }) => {
        const [start, end] = getRangeTime(min, max, startOfDay);

        return (
            <div>
                {start} — {end}
            </div>
        );
    };

    const renderRoute = route => {
        const rangeValues = values.find(value => value.routeIndex === route.index)?.values || { min: 0, max: 86400 };
        const { departureTime } = route;

        return (
            <RouteBlock key={`${route.routeIndex}-${route.departureCityCode}-${route.arrivalCityCode}`}>
                <RouteCities>
                    {included.city[route.departureCityCode].name[language]} →{' '}
                    {included.city[route.arrivalCityCode].name[language]}
                </RouteCities>

                <RangeSlider
                    range={{ min: 0, max: 86400 }}
                    step={900}
                    filter={rangeValues}
                    handleChange={value => handleChange(route.index, value)}
                    titleComponent={(min, max) => (
                        <TitleComponent min={min} max={max} startOfDay={departureTime.defaultRange[0].startOf('day')} />
                    )}
                />
            </RouteBlock>
        );
    };

    return (
        <FilterBlock title={t.departure_time} isOpen={isActive} borderBottom>
            {routesData.map(route => renderRoute(route))}
        </FilterBlock>
    );
};

export default DepartureTimeFilter;
