import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/closeIcon.svg';

export const ClearRoutesButtonContainer = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin: 12px;
    align-items: center;
`;

export const ClearRoutesButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: ${props => (props.disabled ? 'disabled' : 'pointer')};
    font-size: 14px;
    color: ${props => (props.disabled ? '' : '#044476')};
    margin-left: 8px;
    display: flex;
    align-items: center;
`;

export const CloseIconImg = styled(CloseIcon)`
    width: 20px;
    width: 20px;
    margin: 0 4px;
`;
