import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Dialog, DialogContent, DialogTitle, IconButton, CircularProgress } from '@material-ui/core';
import { ArrowRight, Close as CloseIcon } from '@material-ui/icons';

import { useStyles } from '../../../../../containers/search/styles';
import useLocalization from '../../../../../contexts/localization/hook';

import { DesktopHistorySearch, ClearAllHistoryButton, Routes, SearchBlock, RoutesBlock } from './components';

const DesktopSearchHistory = props => {
    const { getHistory, loading, items, cities, flightClassItems, clearHistory } = props;
    const [openModal, setOpenModal] = useState(false);
    const { t, language } = useLocalization();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        if (openModal) {
            getHistory();
        }
    }, [openModal]);

    const onClose = () => {
        setOpenModal(false);
    };

    const goToTheHistoryPage = searchId => {
        history.push(`/result/${searchId}`);
        window.location.reload();
    };

    return (
        <div>
            <DesktopHistorySearch onClick={() => setOpenModal(true)}>{t.search_history}</DesktopHistorySearch>

            <Dialog open={openModal} onClose={() => setOpenModal(false)} scroll="paper" maxWidth="sm" fullWidth>
                <DialogTitle
                    id="mobile-recommendation-info-modal-title"
                    className={classes.titleStyles}
                    disableTypography
                >
                    {t.search_history}

                    <IconButton onClick={() => onClose()} className={classes.closeButtonStyles}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {loading ? (
                        <div className={classes.loading}>
                            <CircularProgress style={{ color: '#044476' }} />
                        </div>
                    ) : (
                        <>
                            {items.map((item, itemIndex) => (
                                <SearchBlock onClick={() => goToTheHistoryPage(item.searchId)} key={`item-${item.searchTime}`}>
                                    <RoutesBlock>
                                        {item.routes.map((route, routeIndex) => (
                                            <Routes key={`route-${itemIndex}-${routeIndex}`}>
                                                {cities.find(city => city.code === route.from)?.value[language]} (
                                                {route.from}) -{' '}
                                                {cities.find(city => city.code === route.to)?.value[language]} (
                                                {route.to}) - {moment(route.date).format('DD.MM.YYYY')}
                                            </Routes>
                                        ))}

                                        <div style={{ paddingRight: 8 }}>
                                            {t.passengers}: {item.passengers.adt}
                                        </div>
                                        <div>{flightClassItems.find(x => x.value === item.cabin)?.label}</div>
                                    </RoutesBlock>

                                    <ArrowRight />
                                </SearchBlock>
                            ))}
                        </>
                    )}

                    {items.length > 0 && (
                        <div className={classes.footer}>
                            <ClearAllHistoryButton onClick={() => clearHistory()}>{t.clear}</ClearAllHistoryButton>
                        </div>
                    )}

                    {items.length === 0 && !loading && <div className={classes.footer}>{t.no_result}</div>}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DesktopSearchHistory;
