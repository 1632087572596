import React, { useEffect, useState } from "react";
// import ClientsRewiev from './parts/clientsRewiev';
// import LastNews from './parts/lastNews';
import PopularDestinationTable from "./parts/popularDestination";
// import SubscriptionToNewsletter from './parts/subscriptionToNewsletter';
import ToursAndDiscounts from "./parts/toursAndDiscounts";
import OurPartners from "./parts/ourPartners";
// import GeneralInfo from './parts/generalInfo';
import API from "../../../lib/api";

const Main = () => {
  const [data, setData] = useState(null);
  const [tours, setTours] = useState(null);

  useEffect(() => {
    getData();
    getTours();
  }, []);

  const getData = async () => {
    const result = await API.getMainData();

    setData(result);
  };

  const getTours = async () => {
    const result = await API.getTours();

    setTours(result.data);
  };

  if (!data && !tours) return null;

  const { destinations, cities, airports, airLines } =
    data?.popularDestinations || {};

    const destination = destinations?.find(item => item)

  return (
    <>
      {data && (
        <PopularDestinationTable
          key={Math.random(10)}
          popularDestinations={data}
          destination={destination}
          cities={cities}
          airports={airports}
          airLines={airLines}
        />
      )}

      <ToursAndDiscounts tours={tours} />

      {/*<LastNews news={data?.news} />*/}

      {/*<ClientsRewiev reviews={data?.reviews} />*/}

      {/*<GeneralInfo />*/}

      {/*<SubscriptionToNewsletter />*/}

      {/* <OurPartners partners={data?.partners} /> */}
    </>
  );
};

export default Main;
