import styled from 'styled-components';

export const PhoneBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #044476;
    border: 1px solid #53aafa;
    min-width: 50px;
    padding: 8px 12px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    height: 35px;
    margin-right: 16px;

    &:hover {
        cursor: pointer;
        border: 1px solid #044476;
    }

    @media (max-width: 959px) {
        display: block;
        font-size: 18px;
        line-height: 1.6;
        margin: ${props => (props.desktop && props.marginTop ? '16px' : '0')} 0 16px;
        border: none;
        padding: 0;

        &:hover {
            border: none;
        }
    }
`;