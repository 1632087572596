import React from 'react';

import useLocalization from '../../../../contexts/localization/hook';

import FormCheckbox from '../../../ui/components/FormCheckbox/FormCheckbox';

import FilterBlock from '../../resultPage/filters/parts/filterBlock';

import { CheckboxBlock, Label } from './components';

const ToursFilterTypesComponent = ({ items, selected, onChange }) => {
  const { language, t } = useLocalization();

  const handleChange = (value, type) => {
    if (value) {
      onChange({ types: { items, selected: [...selected, type] } });
    } else {
      onChange({ types: { items, selected: selected?.filter(item => item !== type) } });
    }
  };

  return (
    <FilterBlock title={t.tours_types} isOpen borderBottom>
      {items.map(type => (
        <CheckboxBlock>
          <FormCheckbox
            onSelect={value => handleChange(value, type.id)}
            isChecked={selected.includes(type.id)}
          />
          
          <Label>{type.name[language]}</Label>
        </CheckboxBlock>
      ))}
    </FilterBlock>
  );
};

export default ToursFilterTypesComponent;
