import { SET_ALL_LOADING_DATA, SET_SHOW_LOADING, SET_SEARCH_LOADING, SET_SEARCH_DATA } from './constants';

export const setAllLoadngData = data => dispatch =>
    dispatch({
        type: SET_ALL_LOADING_DATA,
        payload: data,
    });

export const setShowLoading = data => dispatch =>
    dispatch({
        type: SET_SHOW_LOADING,
        payload: data,
    });

export const setSearchLoading = data => dispatch =>
    dispatch({
        type: SET_SEARCH_LOADING,
        payload: data,
    });

export const setSearchData = data => dispatch =>
    dispatch({
        type: SET_SEARCH_DATA,
        payload: data,
    });
