import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import TourImagesComponent from "../TourImagesComponent";

import { DescriptionBlock, HotelName, TitleBlock, Wrapper } from "./components";

const TourHotelComponent = ({ hotel }) => {
  const { language, t } = useLocalization();

  return (
    <Wrapper>
      <TitleBlock>{t.tours_information_about_hotel || "Информация об отеле"}</TitleBlock>

      <TourImagesComponent images={hotel.images} />

      <HotelName>{hotel.name[language]}</HotelName>

      <DescriptionBlock>{hotel.description[language]}</DescriptionBlock>
    </Wrapper>
  );
};
export default TourHotelComponent;