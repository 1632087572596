import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
`;

export const TitleBlock = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #404b5a;
`;

export const DescriptionBlock = styled.div`
  color: #404b5a;

  & img {
    max-width: 100% !important;
    height: aut !important;
  }
`;
