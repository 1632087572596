import React, { useState } from "react";

import { useSelector } from "react-redux";

import useLocalization from "../../../../contexts/localization/hook";

import { normalizePrice } from "../../../../lib/normalize";

import TourOrderDialogComponent from "../TourOrderDialogComponent";

import { PriceBlock, PriceDescription, TitleBlock, TourOrderButton, Wrapper } from "./components";

const TourPriceComponent = ({ id, price }) => {
  const { currency } = useSelector(state => state.appConfigs);
  const { t } = useLocalization();

  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <Wrapper>
      <TitleBlock>{t.tours_price}</TitleBlock>

      <PriceBlock>
        <div>{t.from}{" "}<span>{normalizePrice(price)}</span>{" "}{currency}</div>
      </PriceBlock>

      <PriceDescription>
        {t.tours_price_for_one}
      </PriceDescription>

      <TourOrderButton onClick={() => setIsShowModal(true)}>
        {t.tours_order}
      </TourOrderButton>

      <TourOrderDialogComponent isShow={isShowModal} onClose={() => setIsShowModal(false)} id={id} />
    </Wrapper>
  );
};

export default TourPriceComponent;
