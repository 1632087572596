import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Component = styled.div`
    background-color: #1c222a;
`;

export const HideOnMobile = styled.div`
    @media (max-width: 959px) {
        display: none;
    }
`;

export const HideOnPc = styled.div`
    display: none;

    @media (max-width: 959px) {
        display: flex;
    }
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MainBlock = styled.div`
    order: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    padding: 48px 0;

    @media (max-width: 959px) {
        order: 2;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const Column = styled.div`
    width: 36%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 959px) {
        width: 100%;
        margin-bottom: 16px;
    }
`;

export const LogoBlock = styled.div`
    margin-bottom: 16px;
`;

export const ContactUsText = styled.div`
    font-size: 18px;
    color: #84b6df;
    margin-bottom: 8px;
`;

export const ContactsEmail = styled.div`
    color: #84b6df;
    margin-bottom: 8px;
    
    a {
        color: #fff;
        text-decoration: none;
    }
`;

export const ContactPhone = styled.div`
    font-size: 18px;
    color: #84b6df;
    display: flex;
    gap: 8px;

    a {
        color: #fff;
        text-decoration: none;
    }

    .social-icon {
        width: 20px;
    }
`;

export const LinkToDownload = styled.a`
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 8px;

    &::nth-last-of-type(1) {
        margin-bottom: 0;
    }

    &:hover {
        color: #84b6df;
    }
`;

export const LinkToStaticPage = styled(NavLink)`
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 8px;

    &::nth-last-of-type(1) {
        margin-bottom: 0;
    }

    &:hover {
        color: #84b6df;
    }
`;

export const SocialsBlock = styled.div`
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 0;

    @media (max-width: 959px) {
        order: 1;
        border-bottom: 1px solid #d2d2d2;
        padding: 32px 0;
    }
`;

export const SocialsLinks = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 959px) {
        margin-top: 16px;
    }
`;

export const SocialLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgb(228, 233, 243);
    border-radius: 50%;
    color: initial;
    margin-right: 16px;

    &:hover {
        opacity: 0.7;
    }

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
    &:nth-child(1) {
        color: #2f55a4;
    }
    &:nth-child(2) {
        color: red;
    }
`;

export const DontCopyText = styled.div`
    order: 3;
    text-align: center;
    font-size: 12px;
    color: #e4e9f3;
    margin-bottom: 32px;

    @media (max-width: 959px) {
        margin-top: 32px;
    }
`;

export const PaymentMethods = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 1 rem;
`;

export const PaymentMethodsText = styled.div`
    font-size: 18px;
    color: #84b6df;
    margin-bottom: 8px;
`;

export const PaymentMethodsLogos = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PaymentMethodLogo = styled.img`
    max-width: 100px;
    max-height: 150px;
    border: 1px solid white;
    border-radius: 10px;
    margin-right: 10px;
    background-color: #fff;
`;
export const OsmonWhiteLogo = styled.img`
    max-width: 200px;
    margin: 0px;
`;

export const FeedbackModalLink = styled.div`
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 8px;

    &::nth-last-of-type(1) {
        margin-bottom: 0;
    }

    &:hover {
        cursor: pointer;
        color: #84b6df;
    }
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #044476;
    border: 1px solid #53aafa;
    min-width: 50px;
    padding: 8px 12px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    height: 35px;
    margin-right: 16px;

    &:hover {
        cursor: pointer;
        border: 1px solid #044476;
    }

    @media (max-width: 959px) {
        display: block;
        font-size: 18px;
        line-height: 1.6;
        margin: ${props => (props.desktop && props.marginTop ? '16px' : '0')} 0 16px;
        border: none;
        padding: 0;

        &:hover {
            border: none;
        }
    }
`;

export const FormGroup = styled.div`
    width: 100%;
    margin: 0 0 16px;
    position: relative;
`;

export const Label = styled.label`
    position: absolute;
    font-size: 12px;
    top: 8px;
    left: 16px;
    font-weight: bold;
    color: #404b5a;
`;

export const Input = styled.input`
    width: 100%;
    padding: 32px 16px 8px;
    font-size: 16px;
    border: 1px solid ${props => (props.error && props.touched ? '#ff0000' : '#c3d6e8')};
    border-radius: 12px;
`;

export const FormSubmitButton = styled.button`
    margin: 12px auto 0;
    background-color: #044476;
    border: none;
    border-radius: 12px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 12px 32px;
`;

export const ErrorText = styled.div`
    color: #ff0000;
    font-size: 14px;
    margin-top: 4px;
`;

export const NotFoundText = styled.div`
    color: #ff0000;
    font-size: 14px;
    margin-top: 4px;
    text-align: center;
`;

