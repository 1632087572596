import styled from 'styled-components';

export const CheckboxBlock = styled.div`
  @media (max-width: 959px) {
    padding: 4px 0;
  }
`;

export const Label = styled.label`
  margin-left: 8px;
  vertical-align: 6px;
  font-size: 14px;
`;
