import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import { FormHideStyle } from './components';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Label from '../../components/Label/Label';
import InputMessage from '../../components/InputMessage/InputMessage';
import { ErrorsFieldBlock, FormGroup } from '../../../../styles/common/index';
import useLocalization from '../../../../contexts/localization/hook';

const FormField = props => {
    const { t } = useLocalization();

    const {
        label,
        name,
        type,
        meta: { touched, error },
        placeholder,
        disabled,
        input,
        fieldError,
        autoFocus,
        isPadding,
        onClick,
        defaultValue,
        maxLength,
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisible = () => {
        setPasswordVisible(visible => !visible)
    };

    return (
        <FormGroup>
            <Label text={label} />
            <Input
                {...input}
                type={passwordVisible ? 'text' : type}
                maxLength={maxLength}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
                isPadding={isPadding}
                autoComplete="off"
                onClick={onClick}
                defaultValue={defaultValue}
                borderColor={fieldError || (touched && error) ? '#c62828' : ''}
            />

            {type === 'password' && (
                <FormHideStyle onClick={togglePasswordVisible}>{passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}</FormHideStyle>
            )}
            <ErrorsFieldBlock>
                {touched && error && <InputMessage text={t[error]} />}
                {fieldError && <InputMessage text={fieldError} />}
            </ErrorsFieldBlock>
        </FormGroup>
    );
};

FormField.propTypes = {
    input: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    tooltipTitle: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    fieldError: PropTypes.string,
    defaultValue: PropTypes.string,
    meta: PropTypes.object,
    autoFocus: PropTypes.bool,
};

FormField.defaultProps = {
    disabled: null,
    label: null,
    name: null,
    placeholder: null,
    tooltipTitle: null,
    type: 'text',
    fieldError: null,
    defaultValue: '',
    autoFocus: false,
    maxLength: 60,
    onClick: () => { },
    meta: {
        touched: null,
        error: null,
        warning: null,
    },
};

export default FormField;
