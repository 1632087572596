import _ from "lodash";

export const sortByMinPrice = tours => _.sortBy(tours, "price");

export const sortByMaxPrice = tours => sortByMinPrice(tours).reverse();

const getPriceRange = (tours) => {
  const sortingTours = sortByMinPrice(tours);

  return {
    min: Math.floor(_.first(sortingTours).price),
    max: Math.ceil(_.last(sortingTours).price),
  };
};

export const getFiltersData = (tours, included) => {
  const { cities, hotels, types } = included;

  return {
    price: {
      data: { ...getPriceRange(tours) },
      selected: { ...getPriceRange(tours) },
    },

    cities: {
      items: cities,
      selected: [],
    },

    hotels: {
      items: hotels,
      selected: [],
    },

    types: {
      items: types,
      selected: [],
    },
  };
};
