import React from 'react';
import { useHistory } from 'react-router-dom';

import defaultLogo from '../../../../../images/mainLogo/FlyTrip_uz.png';

import { Wrapper, LogoImage } from './components';

const Logo = () => {
    const history = useHistory();

    const handleOnClick = () => {
        history.push('/');
    };

    return (
        <Wrapper>
            <LogoImage alt="Logo icon" src={defaultLogo} onClick={() => handleOnClick()} />
        </Wrapper>
    );
};

export default Logo;
