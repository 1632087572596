import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';

import Search from '../search';

import useStyles from './styles';
import Logo from './parts/logo';
import Nav from './parts/nav';
import SearchOrder from '../searchOrder';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Contacts from './parts/contacts';
import MainBanner from '../main/parts/mainBanner';
import API from '../../../lib/api';

const Header = () => {
    const { showHeader, showSearchBlock } = useSelector(store => store.appConfigs);
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const [data, setData] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const getData = async () => {
            try {
                const result = await API.getMainData();
                if (isMounted) setData(result);
            } catch (error) {
                console.log(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        }
    }, []);

    if (!data) return null;

    if (showHeader) {
        return (
            <>
                <header className={styles.component}>
                    <Container fixed className={styles.container}>
                        <Logo />
                        <Nav />
                    </Container>
                </header>
                {/*<MainBanner tours={data?.tours}/>*/}
                {showSearchBlock && <Search />}
            </>
        );
    }

    return null;
};

export default Header;
