import React from 'react';
import { Container } from '@material-ui/core';

import useLocalization from '../../../../../contexts/localization/hook';

import Carousel from './carousel';
import { Header, Icon, ShowMoreButton, ShowMoreButtonBlock } from './components';
import Discount from '../../../../../assets/icons/percent.svg';

const ToursAndDiscounts = ({ tours }) => {
    const { t, language } = useLocalization();

    const toursByLanguage = tours?.filter(tour => tour.name[language]);

    if (!toursByLanguage?.length) return null;

    return (
        <Container fixed style={{ marginBottom: '6rem' }}>
            <Header>
                <Icon src={Discount} />
                {t.tours}
            </Header>

            <Carousel tours={toursByLanguage} />

            <ShowMoreButtonBlock>
                <ShowMoreButton to="/tours">{t.tours_all || "Все туры"}</ShowMoreButton>
            </ShowMoreButtonBlock>
        </Container>
    )
};

export default ToursAndDiscounts;
