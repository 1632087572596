import React from 'react';

import {PhoneBlock} from './components';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import envConfigs from '../../../../../config/envConfigs'

const Contacts = () => {
    return(
        <>
            <PhoneBlock>
                <PhoneTwoToneIcon/>
                {envConfigs.supportPhone}
            </PhoneBlock>
        </>
    )
}

export default Contacts