import styled from 'styled-components';
import { ExitToApp, AccountCircle } from '@material-ui/icons';

import Link from '../../../../../ui/components/Link/Link';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 599px) {
        display: none;
    }
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const ExitToAppIcon = styled(ExitToApp)`
    width: 28px;
    height: 28px;
    color: #044476;
`;

export const AccountCircleIcon = styled(AccountCircle)`
    width: 42px !important;
    height: 42px !important;
    color: #044476;
`;

export const EnterBlock = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border: 1px solid #044476;
    border-radius: 50%;
    margin-left: 16px;
    cursor: pointer;
    background-color: transparent;
`;

export const Dropdown = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
    position: absolute;
    top: calc(100% + 10px);
    right: ${props => (props.right ? '-12px' : '0')};
    background-color: #fff;
    box-shadow: 1px 10px 10px 0 rgba(0, 59, 110, 0.1);
    padding: 12px;
    border-radius: 4px;
    width: 220px;

    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-right: 10px solid transparent;
        border-bottom: 8px solid #ffffff;
        border-left: 10px solid transparent;
        position: absolute;
        top: -8px;
        right: 22px;
    }

    @media (max-width: 959px) {
        right: ${props => (props.right ? '6px' : '0')};
    }
`;

export const StyledLink = styled(Link)`
    display: block;
    color: #044476;
    cursor: pointer;
    margin-left: 8px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
    margin-top: 8px;
    margin-bottom: 8px;
`;

export const LinkForAgents = styled.a`
    display: block;
    color: #044476;
    font-size: 18px;
    font-weight: bold;
    font-weight: 600;
    line-height: 1.6;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 8px;
    color: #044476;
    text-decoration: none;
`;

export const Component = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const Button = styled.button`
    background-color: ${props => (props.active ? 'hsla(0, 0%, 88.2%, .2)' : 'transparent')};
    color: #fff;
    border: none;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
        cursor: pointer;
    }
`;

export const ClientName = styled.div`
    color: #000;
    font-weight: bold;
    color: #044476;
    margin-left: 8px;
`;

export const ClientLogin = styled.div`
    color: #aeaeae;
    font-size: 14px;
    margin-left: 8px;
    margin-bottom: 8px;
`;

export const ClientBalanceBlock = styled.div`
    display: flex;
    padding: 8px;
    font-size: 14px;
`;

export const ClientBalanceTitle = styled.div`
    color: #aeaeae;
    margin-right: 8px;
`;

export const ClientBalanceValue = styled.div`
    color: #404b5a;
    font-weight: bold;

    & > span {
        margin-left: 4px;
        color: #044476;
    }
`;

export const ExitButton = styled.div`
    color: #000;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 8px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
`;
