import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
`;

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LoadingText = styled.div`
    font-size: 24px;
    font-weight: bold;
    color: #404b5a;
    margin-bottom: 16px;
`;

export const LoadingCircularBlock = styled.div`
    margin-top: 8px;
    animation: ${rotate} 1.6s linear infinite;
`;
