import React, { useState, useCallback, useMemo } from 'react';

import useLocalization from '../../../../../../../../contexts/localization/hook';
import defaultLanguages from '../../../../../../../../config/defaultLanguages';

import { Component, Button, Items, Item, Text, TextLanguage, CheckboxBlock, Checkbox } from './components';
import Dropdown from '../../../../../../ui/dropdown';

import { ReactComponent as CaretIcon } from '../../../../../../../../assets/icons/newDesign/caretIcon.svg';
import { useStyles } from '../../../styles';

const SelectLanguages = () => {
    const [isActive, setIsActive] = useState(false);
    const styles = useStyles();
    const { language, availableLanguages, switchLang } = useLocalization();

    const handleSelectLanguage = useCallback(language => {
        setIsActive(false);
        switchLang(language);
    }, []);

    const renderLanguages = useMemo(() => {
        return availableLanguages.map(value => {
            return (
                <Item key={`languages-${value}`} onClick={() => handleSelectLanguage(value)}>
                    <Text>{value}</Text>

                    <TextLanguage className={value === language && 'active'}>
                        {defaultLanguages[language][value]}
                    </TextLanguage>

                    <CheckboxBlock>
                        <Checkbox className={value === language && 'active'} />
                    </CheckboxBlock>
                </Item>
            );
        });
    }, [availableLanguages, language]);

    return (
        <Component>
            <Button onClick={() => setIsActive(true)}>
                <span className={styles.textCurrency}>{language}</span>{' '}
                <span className={isActive ? styles.caretIcon : null}>
                    <CaretIcon />
                </span>
            </Button>

            <Dropdown isActive={isActive} onClickOutside={() => setIsActive(false)}>
                <Items>{renderLanguages}</Items>
            </Dropdown>
        </Component>
    );
};

export default SelectLanguages;
