import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
    AirlineLogo,
    AirportCode,
    CarrierInfoBlock,
    CityInfo,
    DurationBlock,
    RouteGrid,
    SegmentInfo,
    SegmentsWrapper,
    TechStopInfo,
    TimeInfo,
    TransferBetweenAirportWarning,
    TransferGrid,
    TransferImageDiv,
    TransferTitle,
    WrapperGrid,
} from './components';
import useLocalization from '../../../../contexts/localization/hook';
import { getCityAndAirportNames, getDurationTime, getServiceClass, getTechStops, getTransferTime } from '../utils';
import { getSupplierIcon } from '../../../../lib/getImage';

const FlightDetails = ({ route, recommendation, included, routeIndex }) => {
    const { segments } = route;
    const { t, language } = useLocalization();

    const checkIfTransferAnotherAirport = () => {
        if (segments.length > 1) {
            for (let i = 1; i < segments.length; i++) {
                if (segments[i].departure.airport === segments[i - 1].arrival.airport) {
                    return (
                        <TransferBetweenAirportWarning>{t.no_transfers_between_airports}</TransferBetweenAirportWarning>
                    );
                }
            }
        }

        return null;
    };

    const renderFlightDetails = (segment, index) => {
        const { departure, service_class, arrival, carrier_number, aircraft, tech_stops, operation_supplier } = segment;
        const isTechStops = segment.tech_stops.length > 0;
        const isTransfer = index > 0;

        let aircraftName = aircraft;

        if (included.aircraft[aircraft]) {
            aircraftName = included.aircraft[aircraft].name.en;
        } else {
            aircraftName = '';
        }

        let transferTime = '';

        if (isTransfer) {
            const actualRoute = recommendation.routes[routeIndex];

            transferTime = getTransferTime(actualRoute, index, t.hour_short, t.minutes_short_3);
        }

        return (
            <WrapperGrid container spacing={0}>
                {isTransfer && (
                    <TransferGrid item xs={12}>
                        <TransferImageDiv />
                        <TransferTitle>
                            {t.transfer} {transferTime}
                        </TransferTitle>
                    </TransferGrid>
                )}

                <Grid item xs={3} sm={2}>
                    <DurationBlock>
                        <span>{getDurationTime(segment.duration, t.hour_short, t.minutes_short_3)}</span>
                    </DurationBlock>
                </Grid>

                <RouteGrid item xs={9} sm={7}>
                    <SegmentInfo>
                        <TimeInfo>{departure.time.split(' ')[1]}</TimeInfo>
                        <AirportCode>{departure.airport}</AirportCode>
                        <CityInfo>
                            <span>{getCityAndAirportNames(included.city, included.airport, departure, language)}</span>
                        </CityInfo>
                    </SegmentInfo>

                    <TechStopInfo>
                        {isTechStops && (
                            <div>
                                {getTechStops(
                                    tech_stops,
                                    included.city,
                                    language,
                                    t.hour_short,
                                    t.minutes_short_3,
                                    t.teh_stop
                                )}
                            </div>
                        )}
                    </TechStopInfo>

                    <SegmentInfo>
                        <TimeInfo>{arrival.time.split(' ')[1]}</TimeInfo>
                        <AirportCode>{arrival.airport}</AirportCode>
                        <CityInfo>
                            {getCityAndAirportNames(included.city, included.airport, arrival, language)}
                        </CityInfo>
                    </SegmentInfo>
                </RouteGrid>

                <Grid item xs={12} sm={3}>
                    <CarrierInfoBlock>
                        <div style={{ fontWeight: 'bold', marginBottom: 4 }}>{t.operating_company}</div>
                        <AirlineLogo imgUrl={getSupplierIcon(operation_supplier)} />
                        <div style={{ marginBottom: 4 }}>
                            {t.flight} {carrier_number}
                        </div>
                        <div style={{ marginBottom: 4 }}>{aircraftName}</div>
                        <div style={{ marginBottom: 4 }}>{getServiceClass(service_class, t.class)}</div>
                    </CarrierInfoBlock>
                </Grid>
            </WrapperGrid>
        );
    };

    return (
        <SegmentsWrapper>
            {checkIfTransferAnotherAirport()}
            {segments.map((segment, index) => renderFlightDetails(segment, index))}
        </SegmentsWrapper>
    );
};

export default FlightDetails;
