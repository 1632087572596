import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled(Grid)`
    margin-top: 8px;
    margin-bottom: 32px;
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;

    @media (max-width: 1279px){
        padding: 12px;
    };

    @media (max-width: 959px){
        padding: 0;
    };
`;

export const BannerImage = styled.img`
    width: 100%;
    height: 500px;
    display: block;
    border-radius: 20px;

    @media screen and (max-width: 1279px){
        // width: 90%;
        height: 300px;
        border-radius: 12;
    };

    @media screen and (max-width: 959px){
        width: 100%;
        height: 260px;
        border-radius: 0;
    };
`;

export const BannerText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    font-weight: 600;
    color: #fff;

    @media (max-width: 1010px){
        font-size: 30px;
    };

    @media (max-width: 750px){
        font-size: 20px;
    }
`;

export const BannerDot = styled.div`
    width: 16px;
    height: 16px;
    background-color: #E4E9F3;
    border-radius: 50%;
    @media screen and (max-width: 959px){
        width: 14;
        height: 14;
    };

    @media screen and (max-width: 959px){
        width: 12;
        height: 12;
    };
`;
