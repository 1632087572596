import React from 'react';
import moment from 'moment';

import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import ModalComponent from '../../../ui/modal';
import Dropdown from '../../../ui/dropdown';
import Calendar from './calendar';
import { DropdownHeader, DropdownHeaderButton, DropdownHeaderTitle, OnlyThereButton } from './components';
import useLocalization from '../../../../../contexts/localization/hook';

const ScrollingCalendar = ({
    type,
    isActive,
    onSelect,
    onClose,
    value,
    activeDirection,
    setActiveDirection,
    lastSelectedDate,
}) => {
    const { width } = useWindowDimensions();
    const { t } = useLocalization();

    const handleSelect = selected => {
        if (selected.disabled) return;

        if (type === 'oneSelect') {
            onSelect(selected.date);
            onClose();
        } else {
            let dates = [...value];

            if (activeDirection === 'from') {
                dates[0] = selected.date;
                onSelect(dates);
                setActiveDirection('to');
            } else {
                dates[1] = selected.date;

                if (moment(dates[1]).isBefore(dates[0])) {
                    dates = [dates[1], dates[0]];
                }

                onSelect(dates);
                onClose();
            }
        }
    };

    const handleOnlyThereClick = () => {
        let dates = [...value];

        if (dates[1]) {
            dates[1] = null;
        }

        onSelect(dates);
        onClose();
    };

    if (width >= 960 && isActive) {
        return (
            <Dropdown
                isActive={isActive}
                onClickOutside={onClose}
                position="left"
                width="100%"
                boxShadow="0 2px 5px 0 rgba(0, 0, 0, 0.25)"
                padding="0 0 16px"
            >
                <DropdownHeader>
                    <DropdownHeaderTitle>
                        {activeDirection === 'from' ? t.select_dep_date : t.select_return_date}
                    </DropdownHeaderTitle>
                    {activeDirection === 'to' && (
                        <DropdownHeaderButton onClick={handleOnlyThereClick}>{t.only_there}</DropdownHeaderButton>
                    )}
                </DropdownHeader>
                <Calendar
                    selectedDates={value}
                    handleSelect={handleSelect}
                    type={type}
                    activeDirection={activeDirection}
                    setActiveDirection={direction => setActiveDirection(direction)}
                    lastSelectedDate={lastSelectedDate}
                />
            </Dropdown>
        );
    }

    return (
        <ModalComponent
            active={isActive}
            onClose={onClose}
            title={activeDirection === 'from' ? t.select_dep_date : t.select_return_date}
        >
            <Calendar
                selectedDates={value}
                handleSelect={handleSelect}
                type={type}
                activeDirection={activeDirection}
                setActiveDirection={direction => setActiveDirection(direction)}
                lastSelectedDate={lastSelectedDate}
            />
            {activeDirection === 'to' && (
                <OnlyThereButton onClick={handleOnlyThereClick}>{t.only_there}</OnlyThereButton>
            )}
        </ModalComponent>
    );
};

export default ScrollingCalendar;
