import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import punktirNew from '../../../../images/punktirnew.png';
import circles from '../../../../images/circles-blue.png';

export const SegmentsWrapper = styled.div`
    padding: 16px 0;
    background-color: #fff;
`;

export const TransferBetweenAirportWarning = styled.div`
    padding-left: 16px;
    color: #ff531d;
`;

export const WrapperGrid = styled(Grid)`
    flex-grow: 1;
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 6px;
    border-top: ${props => (props.borderTop ? 'solid 1px #cfd8dc' : 'none')};
`;

export const TransferGrid = styled(Grid)`
    padding-left: 160px;
    font-size: 16px;
    padding-top: 7px;
    padding-bottom: 24px;
    position: relative;
`;

export const TransferImageDiv = styled.div`
    height: 100px;
    width: 10px;
    background-image: url(${punktirNew});
    background-repeat: no-repeat;
    background-size: 2px 100px;
    position: absolute;
    left: 148px;
    bottom: 0;
`;

export const TransferTitle = styled.div`
    position: relative;
    bottom: 14px;
`;

export const DurationBlock = styled.div`
    height: 20px;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #9da8ae;
    padding-top: 22px;
`;

export const RouteGrid = styled(Grid)`
    background-image: url(${circles});
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 12px 70px;

    @media (max-width: 599px) {
        margin-bottom: 25px;
    }
`;

export const SegmentInfo = styled.div`
    height: 24px;
    padding-left: 16px;
`;

export const TechStopInfo = styled.div`
    height: 24px;
    font-size: 12px;
    padding-left: 24px;
    color: #7e878b;
`;

export const TimeInfo = styled.span`
    height: 24px;
    font-size: 16px;
    font-weight: 500;
`;

export const AirportCode = styled.span`
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    padding-left: 16px;
`;

export const CityInfo = styled.span`
    font-size: 16px;
    color: #9da8ae;
    padding-left: 8px;
`;

export const CarrierInfoBlock = styled.div`
    text-align: right;
    padding-right: 26px;
    font-size: 14px;
    color: #000;
    line-height: 1.41;

    @media (max-width: 599px) {
        padding-right: 0;
        text-align: center;
    }
`;

export const AirlineLogo = styled.div`
    height: 30px;
    width: 100%;
    background-image: url(${props => props.imgUrl});
    background-repeat: no-repeat;
    background-position: center center;
`;
