import styled from "styled-components";

export const Destinations = styled("div")(({ theme }) => ({
  marginBottom: "48px",

  h2: {
    margin: "0 0 14px 0",
    color: theme?.palette?.mainText,
  },
}));

export const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1.6rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#404b5a',
    paddingBottom: 32,
});

export const Airport = styled("h3")(({ theme }) => ({
  color: "#404B5A",
  textAlign: "center",
  marginTop: 0,
  fontWeight: 400,
}))

export const Icon = styled('img')({
  maxWidth: 30,
  maxHeight: 30,
  marginRight: 12,
    '@media (max-width: 959px)': {
        display: 'none',
    }
});

export const ItemWrapper = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridAutoRows: "230px",
  gap: "16px",

  "@media screen and (max-width: 1280px)": {
	gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media screen and (max-width: 960px)": {
	gridTemplateColumns: "1fr",
  }
}));

export const Figure = styled("figure")(({ theme }) => ({
  display: "block",
  padding: "0",
  margin: "0",
  position: "relative",
  overflow: "hidden",
  transition: "300ms",
  borderRadius: "16px",
  cursor: "pointer",
  background:
      `calc( .9*16px) calc( .9*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 20% 20%,#0000 75%,#fbb600 0),
      calc(-.1*16px) calc(-.1*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 20% 20%,#0000 75%,#fbb600 0),
      calc( .7*16px) calc( .7*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 40% 40%,#0000 75%,#0085ff 0),
      calc(-.3*16px) calc(-.3*16px)/calc(2*16px) calc(2*16px) conic-gradient(at 40% 40%,#0000 75%,#0085ff 0),
      conic-gradient(from 90deg at 20% 20%,#fbb600 25%,#0085ff 0) 
       0 0/16px 16px;`,

  img: {
    width: "100%",
    objectFit: "cover",
    borderRadius: "16px",
    height: "100%",
    transition: "300ms 0ms",
  },

  figcaption: {
    height: "40%",
    transition: "300ms",

    div: {
      transition: "300ms 100ms",
    },
  },

  "&:hover": {
    img: {
      transform: "scale(1.3)",
      transition: "3s 150ms",
    },
    figcaption: {
      height: "100%",

      div: {
        marginBottom: "8px",
      },
    },
  },
}));

export const Figcaption = styled("figcaption")(({ theme }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: "10px",
  color: "#ffffff",
  borderRadius: "16px",
  background: "rgb(0,0,0)",
  // eslint-disable-next-line no-dupe-keys
  background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",

  span: {
    fontSize: "18px",
    fontWeight: "500",
  },

  div: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    width: "100%",
    gap: "0 1rem",

    "span:nth-child(2)": {
      justifySelf: "end",
    },

    small: {
      opacity: 0.7,
      fontSize: "14px",
      gridColumn: "1/3",
    },
  },
}));

export const SpinerLoader = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50px",
  height: "50px",
  color: "#ffffff",
}));

export const ShowMore = styled("button")`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #044476;
  border-radius: 24px;
  background-color: transparent;
  height: 35px;
  color: #044476;
  cursor: pointer;
  margin-bottom: 32px;
  padding: 0 16px;
  margin: 32px auto 0 auto;
`;
