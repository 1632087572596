import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";

import useLocalization from "../../../../contexts/localization/hook";

import { getImgFromUploads } from "../../../../lib/getImage";

import {
  StyledTourAttribute,
  StyledTourAttributes,
  StyledTourAttributesTitle,
  StyledTourBadges,
  StyledTourCity,
  StyledTourImage,
  StyledTourIsLastMinute,
  StyledTourIsNew,
  StyledTourMoreInfoButton,
  StyledTourName,
  StyledTourPriceButton,
  StyledTourPriceDescription,
  Wrapper,
} from "./components";

const ToursItemComponent = ({ tour, included }) => {
  const { attributes, cities } = included;

  const includedToPrice = tour.attributes?.filter(attr => attr.price_type === "included");
  const notIncludedToPrice = tour.attributes?.filter(attr => attr.price_type === "not_included");
  const additionalToPrice = tour.attributes?.filter(attr => attr.price_type === "additional");
    
  const getImageUrl = () => {
    if (tour?.images.length) return getImgFromUploads(tour?.images[0]?.url);
        
    return null;
  };

  const { currency } = useSelector(state => state.appConfigs);

  const history = useHistory();

  const { language, t } = useLocalization();

  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);

  const handleGoToTourPage = () => {
    history.push(`/tour/${tour.id}`);
  };

  return (
    <Wrapper>
      <StyledTourImage $imageUrl={getImageUrl()} />

      <StyledTourBadges>
        {tour.is_last_minute && (<StyledTourIsLastMinute>{t.tours_last_minute}</StyledTourIsLastMinute>)}
        {tour.is_new && (<StyledTourIsNew>{t.tours_new}</StyledTourIsNew>)}
      </StyledTourBadges>

      <StyledTourName>{tour.name[language]}</StyledTourName>

      <StyledTourCity>{cities?.find(city => city.id === tour.city_id)?.name[language]}</StyledTourCity>

      <StyledTourAttributes>
        {isShowMoreInfo && <StyledTourAttributesTitle>{t.tours_included_to_price}</StyledTourAttributesTitle>}

        {includedToPrice.map((attribute, index) => {
          if ((index > 3 && !isShowMoreInfo)) return null;

          return (
            <StyledTourAttribute $type="success" key={attribute.id}>
              <CheckCircleIcon />
              <span>{attributes?.find(attr => attr.id === attribute.id)?.name[language]}</span>
            </StyledTourAttribute>
          );
        })}
      </StyledTourAttributes>

      {(isShowMoreInfo && notIncludedToPrice.length !== 0) && (
        <StyledTourAttributes>
          <StyledTourAttributesTitle>{t.tours_not_included_to_price}</StyledTourAttributesTitle>
            
          {notIncludedToPrice.map(attribute => (
            <StyledTourAttribute $type="error" key={attribute.id}>
              <CancelIcon />
              <span>{attributes?.find(attr => attr.id === attribute.id)?.name[language]}</span>
            </StyledTourAttribute>
          ))}
        </StyledTourAttributes>
      )}

      {(isShowMoreInfo && additionalToPrice.length !== 0) && (
        <StyledTourAttributes>
          <StyledTourAttributesTitle>{t.tours_additional_to_price}</StyledTourAttributesTitle>
            
          {notIncludedToPrice.map(attribute => (
            <StyledTourAttribute $type="warning" key={attribute.id}>
              <ErrorRoundedIcon />
              <span>{attributes?.find(attr => attr.id === attribute.id)?.name[language]}</span>
            </StyledTourAttribute>
          ))}
        </StyledTourAttributes>
      )}

      <StyledTourPriceButton onClick={handleGoToTourPage}>{t.from} {tour.price} {currency}</StyledTourPriceButton>

      <StyledTourPriceDescription>
        {t.tours_price_for_one}
      </StyledTourPriceDescription>

      <StyledTourMoreInfoButton onClick={() => setIsShowMoreInfo(prev => !prev)} $isActive={isShowMoreInfo}>
        <ChevronRightIcon />
      </StyledTourMoreInfoButton>
    </Wrapper>
  );
};

export default ToursItemComponent;
