import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import noun from 'plural-ru';
import Divider from '@material-ui/core/Divider';

import { getSupplierIcon } from '../../../../../../lib/getImage';
import { getDurationTime, getServiceClass } from '../../../utils';
import { RT } from '../../../../../../containers/search/constants';
import { normalizePrice } from '../../../../../../lib/normalize';
import useLocalization from '../../../../../../contexts/localization/hook';

import TariffsForm from '../../../tariffsBlock/mobile';
import FlightInfoIcons from '../../../flightInfoIcons';
import ModalComponent from '../../../../ui/modal';
import {
    ActionsBlock,
    BuyButton,
    DepartureBlock,
    OperatingCompanyBlock,
    RouteBlock,
    RouteDuration,
    SegmentBlock,
    SegmentBody,
    SegmentFooter,
    SegmentHeader,
    SegmentHeaderDuration,
    SegmentHeaderTitle,
    SegmentSupplierIconBlock,
    SegmentFooterFlightClass,
    TabContent,
    TabsComponent,
    TariffsTab,
    TransfersBetweenAirportsWarning,
    SegmentFooterFlightAdditionalInfo,
    SegmentFooterFlightAdditionalInfoItems,
    SegmentFooterFreeSits,
    SegmentFooterFlightAdditionalInfoItem,
    PriceBlock,
    ValidatingSupplier,
    Price,
    PriceForTicket,
    LowcostBlock,
    Lowcost,
    Charter,
    SegmentFlightInfoBlock,
    SegmentFlightDateTime,
    SegmentFlightDate,
    SegmentFlightTime,
    SegmentFlightAirport,
    SegmentFlightInfoSubTitle,
    SegmentFlightInfoTitle,
} from './components';

const MobileItemModal = ({ recommendation, getFareRules, getBrandFares, open, onClose, onBookingClick }) => {
    const { included, brandFares, brandRules, session } = useSelector(state => state.resultData);
    const { flightType } = useSelector(state => state.searchParams);
    const { currency } = useSelector(state => state.appConfigs);

    const { total_price, rec_id, routes, lowcost, brandRecId, brand_name, has_branded_tariffs, validating_supplier } =
        recommendation;
    const route = _.first(routes);
    const departureSegment = _.first(route.segments);
    const { is_charter } = departureSegment;
    const webCurrency = localStorage.getItem('currency');
    const { t, language } = useLocalization();
    const [tabActive, setTabActive] = useState(0);
    const [currentRecId, setCurrentRecId] = useState(null);

    let actualTotalPrice = total_price;

    if (brandRecId) {
        const { rec_id } = recommendation;
        const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

        actualTotalPrice = actualBrandFare.total_price;
    }

    const getDepartureTitle = (index, first, last) => {
        const { city } = included;
        if (flightType === RT) {
            if (index === 0) {
                return `${t.there_title}: ${city[first.city].name[language]} - ${city[last.city].name[language]}`;
            } else {
                return `${t.back_title}: ${city[first.city].name[language]} - ${city[last.city].name[language]}`;
            }
        }

        return null;
    };

    const renderRoute = (item, index) => {
        let { duration, segments } = item;
        let actualSegments = JSON.parse(JSON.stringify(segments));

        if (brandRecId) {
            const { rec_id } = recommendation;
            const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

            actualSegments = actualBrandFare.routes[index].segments;
        }

        const first = _.first(actualSegments).departure;
        const last = _.last(actualSegments).arrival;

        return (
            <RouteBlock key={`route-block-${Math.random()}`}>
                <DepartureBlock>{getDepartureTitle(index, first, last)}</DepartureBlock>
                <RouteDuration>
                    {t.on_way}: {getDurationTime(duration, t.hour_short, t.minutes_short_3)}
                </RouteDuration>
                {checkIfTransferAnotherAirport(actualSegments)}
                {actualSegments.map((value, index) => {
                    return renderSegment(value, index);
                })}
            </RouteBlock>
        );
    };

    const checkIfTransferAnotherAirport = segments => {
        if (segments.length > 1) {
            for (let i = 1; i < segments.length; i++) {
                if (segments[i].departure.airport === segments[i - 1].arrival.airport) {
                    return (
                        <TransfersBetweenAirportsWarning>
                            {t.no_transfers_between_airports}
                        </TransfersBetweenAirportsWarning>
                    );
                }
            }
        }
        return null;
    };

    const getFreeSeatsLabel = freeSeats => {
        if (freeSeats === 1) {
            return freeSeats + ' ' + t.free_seats_1;
        }

        if (freeSeats > 1 && freeSeats < 5) {
            return freeSeats + ' ' + t.free_seats_2_4;
        }

        if (freeSeats > 4 && freeSeats < 9) {
            return freeSeats + ' ' + t.free_seats_5;
        }

        if (freeSeats > 8) {
            return '9+ ' + t.free_seats_5;
        }
    };

    const renderFlightAirports = value => {
        return (
            <SegmentFlightInfoBlock>
                <SegmentFlightDateTime>
                    <SegmentFlightDate>{value.time.split(' ')[0]}</SegmentFlightDate>
                    <SegmentFlightTime>{value.time.split(' ')[1]}</SegmentFlightTime>
                </SegmentFlightDateTime>
                <SegmentFlightAirport>
                    <SegmentFlightInfoSubTitle>
                        {included.airport[value.airport].name[language]}
                    </SegmentFlightInfoSubTitle>
                    <SegmentFlightInfoTitle>{value.airport}</SegmentFlightInfoTitle>
                </SegmentFlightAirport>
            </SegmentFlightInfoBlock>
        );
    };

    const renderSegment = segment => {
        const {
            operation_supplier,
            carrier_number,
            duration,
            arrival,
            departure,
            service_class,
            free_seats,
            baggage,
            baggage_weight,
            hand_luggage,
            hand_luggage_weight,
        } = segment;

        return (
            <SegmentBlock key={`segment-${Math.random()}`}>
                <SegmentHeader>
                    <SegmentHeaderTitle>
                        {t.flight} {operation_supplier}-{carrier_number}
                    </SegmentHeaderTitle>
                    <SegmentHeaderDuration>
                        {getDurationTime(duration, t.hour_short, t.minutes_short_3)}
                    </SegmentHeaderDuration>
                </SegmentHeader>
                <SegmentBody>
                    <OperatingCompanyBlock>{t.operating_company}</OperatingCompanyBlock>
                    <SegmentSupplierIconBlock>
                        <img src={getSupplierIcon(operation_supplier)} alt="supplier-icon" />
                    </SegmentSupplierIconBlock>
                    {renderFlightAirports(departure)}
                    {renderFlightAirports(arrival)}
                </SegmentBody>
                <SegmentFooter>
                    <SegmentFooterFlightClass>{getServiceClass(service_class, t.class)}</SegmentFooterFlightClass>
                    <SegmentFooterFlightAdditionalInfo>
                        <SegmentFooterFlightAdditionalInfoItems>
                            <FlightInfoIcons data={segment} />
                            <SegmentFooterFreeSits>{getFreeSeatsLabel(free_seats)}</SegmentFooterFreeSits>
                        </SegmentFooterFlightAdditionalInfoItems>
                        <SegmentFooterFlightAdditionalInfoItem>
                            {renderBaggage(baggage, baggage_weight)}
                        </SegmentFooterFlightAdditionalInfoItem>
                        <SegmentFooterFlightAdditionalInfoItem>
                            {renderHandLuggage(hand_luggage, hand_luggage_weight)}
                        </SegmentFooterFlightAdditionalInfoItem>
                    </SegmentFooterFlightAdditionalInfo>
                </SegmentFooter>
            </SegmentBlock>
        );
    };

    const renderBaggage = (baggage, baggage_weight) => {
        let resultText = t.no_baggage;

        if (baggage !== 0 && baggage !== '0') {
            resultText = `${t.baggage}: ${baggage} ${noun(baggage, t.place_one, t.place_more, t.place_many)}`;

            if (baggage_weight) {
                resultText = `${resultText} (${baggage_weight} ${t.weight_unit})`;
            }
        }

        return resultText;
    };

    const renderHandLuggage = (hand_luggage, hand_luggage_weight) => {
        let resultText = '';

        if (hand_luggage !== 0 && hand_luggage !== '0') {
            resultText = `${t.hand_luggage}: ${hand_luggage} ${noun(
                hand_luggage,
                t.place_one,
                t.place_more,
                t.place_many
            )}`;

            if (hand_luggage_weight) {
                resultText = `${resultText} (${hand_luggage_weight} ${t.weight_unit})`;
            }
        }

        return resultText;
    };

    const getTitle = () => {
        const route = routes[0];
        const { segments } = route;
        const first = _.first(segments).departure;
        const last = _.last(segments).arrival;
        return `${t.ticket} ${first.airport} - ${last.airport}`;
    };

    const handleTabChange = (e, newActive) => {
        setTabActive(newActive);

        if (newActive === 1) {
            getBrandFares(rec_id);
        }
    };

    const TabPanel = props => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <TabContent>{children}</TabContent>}
            </div>
        );
    };

    return (
        <ModalComponent active={open} title={getTitle()} onClose={onClose}>
            <Divider variant="middle" />
            <div style={{ padding: 0 }}>
                <TabsComponent
                    value={tabActive}
                    onChange={handleTabChange}
                    aria-label="main TabsComponent"
                    variant="fullWidth"
                >
                    <TariffsTab label={t.main} id="main-tab-0" />
                    {has_branded_tariffs && <TariffsTab label={t.tariffs} id={'main-tab-1'} />}
                </TabsComponent>

                <TabPanel value={tabActive} index={0}>
                    <PriceBlock>
                        <ValidatingSupplier>
                            <img src={getSupplierIcon(validating_supplier)} alt="supplier-icon" />
                        </ValidatingSupplier>

                        <Price>
                            <PriceForTicket>{normalizePrice(actualTotalPrice[webCurrency])}</PriceForTicket>
                            {webCurrency}
                        </Price>

                        <LowcostBlock>{lowcost && <Lowcost>{t.lowcost}</Lowcost>}</LowcostBlock>

                        <LowcostBlock>{is_charter && <Charter>{t.charter}</Charter>}</LowcostBlock>
                    </PriceBlock>
                    {routes.map((value, index) => {
                        return renderRoute(value, index);
                    })}
                </TabPanel>

                {has_branded_tariffs && (
                    <TabPanel value={tabActive} index={1}>
                        {tabActive === 1 && (
                            <TariffsForm
                                recId={rec_id}
                                brandRecId={brandRecId}
                                brandFares={brandFares[rec_id]}
                                brandRules={brandRules}
                                brandName={brand_name}
                                getFareRules={getFareRules}
                                currency={currency}
                                currentRecId={currentRecId}
                                setCurrentRecId={setCurrentRecId}
                            />
                        )}
                    </TabPanel>
                )}
            </div>

            <ActionsBlock>
                <BuyButton color="default" fullWidth onClick={() => onBookingClick(brandRecId || rec_id, session)}>
                    {t.choose}
                </BuyButton>
            </ActionsBlock>
        </ModalComponent>
    );
};

export default MobileItemModal;
