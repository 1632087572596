import styled from 'styled-components';

export const Wrapper = styled.div`
    display: none;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 599px) {
        display: flex;
    }
`;
