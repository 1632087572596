import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  width: 100%;
`;

export const Checkbox = styled.div`
  background-color: #fff;
  width: 16px;
  height: 16px;
  position: relative;
  border: 1px solid #044476;
  border-radius: 50%;
  transition: border-color 0.3s ease;
  margin-right: 8px;
  cursor: pointer;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: transparent;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.3s ease;
  }

  &.active {
    border: 3px solid #044476;
  }
`;

export const Text = styled.div`
  color: inherit;
  line-height: 1.5;
  user-select: none;
  cursor: pointer;
`;
