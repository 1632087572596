import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { PanoramaFishEye, RadioButtonChecked } from '@material-ui/icons';

import useLocalization from '../../../../../../contexts/localization/hook';

import RulesModal from '../../rulesModal';
import {
    BrandCheckedIconGrid,
    BrandFareInfoGrid,
    BrandNameGrid,
    BrandTariffsRulesGrid,
    BrandTariffsRulesLabel,
    BrandTotalPriceGrid,
    FareGrid,
    Wrapper,
} from './components';
import TariffInfo from '../tariffInfo';

const TariffItem = ({ brandFare, isChecked, checkTariffs, handleGetFareRules, brandRules, currency }) => {
    const { t } = useLocalization();
    const { brand_name, total_price, rec_id } = brandFare;
    const brandRule = brandRules[rec_id];
    const [openRules, setOpenRules] = useState(!!brandRule);
    const isLoadingRules = openRules && !brandRule;

    const handleOpenRules = recId => {
        if (!isLoadingRules) {
            setOpenRules(true);

            handleGetFareRules(recId);
        }
    };

    const handleCloseRules = () => {
        setOpenRules(false);
    };

    return (
        <Wrapper>
            <FareGrid container>
                <BrandNameGrid item>{brand_name}</BrandNameGrid>

                <BrandCheckedIconGrid item onClick={() => checkTariffs(rec_id)}>
                    {isChecked ? <RadioButtonChecked /> : <PanoramaFishEye />}
                </BrandCheckedIconGrid>

                <BrandTotalPriceGrid item>
                    {total_price[currency]} {currency}
                </BrandTotalPriceGrid>

                <BrandFareInfoGrid item>
                    <TariffInfo brandFare={brandFare} />
                </BrandFareInfoGrid>

                <BrandTariffsRulesGrid item>
                    <BrandTariffsRulesLabel loading={isLoadingRules} onClick={() => handleOpenRules(rec_id)}>
                        {t.tariff_rules}

                        {isLoadingRules && <CircularProgress style={{ color: '#044476', marginLeft: 16 }} size={16} />}
                    </BrandTariffsRulesLabel>
                </BrandTariffsRulesGrid>

                {brandRule && (
                    <div>
                        <RulesModal
                            openModal={openRules}
                            handleCloseModal={handleCloseRules}
                            fareRules={brandRule.fare_rules}
                            routes={brandRule.routes}
                            included={brandRule.included}
                        />
                    </div>
                )}
            </FareGrid>
        </Wrapper>
    );
};

export default TariffItem;
