import moment from 'moment';

export const appendToLocalStorage = (searchId, dateFrom) => {
    let searchHistory = getActualHistory();

    searchHistory = searchHistory?.filter(x => x.searchId !== searchId);

    searchHistory.push({
        expires: moment(dateFrom).unix() + 86399, // Из первого роута берем дату вылета
        searchTime: Math.floor(Date.now() / 1000),
        searchId,
    });

    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
};

export const addToLocalStorage = history => {
    localStorage.setItem('searchHistory', JSON.stringify(history));
};

export const getActualHistory = () => {
    const searchHistory = getFromLocalStorage();

    if (searchHistory) {
        const actualHistory = removeExpiredSearches(JSON.parse(searchHistory));
        localStorage.setItem('searchHistory', JSON.stringify(actualHistory));
        return actualHistory;
    } else {
        return [];
    }
};

export const removeExpiredSearches = searches => {
    return searches?.filter(search => search.expires > Math.floor(Date.now() / 1000));
};

export const getFromLocalStorage = () => {
    return localStorage.getItem('searchHistory');
};
