import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Header = styled.h1`
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #404b5a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
`;

export const Icon = styled.img`
    @media (max-width: 959px) {
        display: none;
    }
`;

export const CardBlock = styled.div`
    box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
    border-radius: 12px;
    background-color: #fff;

    @media (max-width: 1279px) {
        width: 100%;
    }

    @media (max-width: 959px) {
        display: none;
        width: 100%;
        margin-bottom: 16px;

        &:nth-child(1) {
            display: block;
        }
    }
`;

export const NewsImage = styled.div`
    background: url(${props => props.backgroundImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: ${props => (props.noneBorderRadius ? 'none' : '12px')};
    min-height: ${props => (props.minHeight ? '500px' : '250px')};

    @media (max-width: 599px) {
        min-height: 200px;
    }
`;

export const NewsInform = styled.div`
    padding: 16px;
`;

export const HeaderNews = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #404b5a;
    height: 50px;
    overflow: hidden;
`;

export const DateNews = styled.p`
    font-family: Roboto;
    color: #404b5a;
`;

export const ButtonToOpenMore = styled(NavLink)`
    height: 35px;
    border-radius: 20px;
    background-color: transparent;
    color: #044476;
    border: 1px solid #044476;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
`;

export const NewsItem = styled.div`
    padding: 12px 16px;

    @media (max-width: 959px) {
        width: 100% !important;
    }
`;

export const ArrowIcons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 20px 16px 0;

    &.hide {
        display: none;
    }

    @media (max-width: 1279px) {
        &.hide {
            display: block;
            float: right;
        }
    }
`;

export const IconButton = styled.img``;
