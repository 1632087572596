import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
`;

export const StyledTourImage = styled.div`
  height: 230px;
  width: 100%;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: url(${props => props.$imageUrl});
  background-size: cover;
`;

export const StyledTourName = styled.div`
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
  color: #404b5a;
`;

export const StyledTourCity = styled.div`
  padding: 0 16px;
  font-size: 12px;
  font-weight: bold;
  color: #9da8ae;
`;

export const StyledTourAttributes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 8px;
`;

export const StyledTourAttributesTitle = styled.div`
  font-size: 14px;
  color: #404b5a;
  font-weight: bold;
`;

export const StyledTourAttribute = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;

  ${props => props.$type === "success" ? "color: #5ba554;" : ""}
  ${props => props.$type === "error" ? "color: #c62828;" : ""}
  ${props => props.$type === "warning" ? "color: #f7b407;" : ""}

  & > span {
    color: #404b5a;
  }
`;

export const StyledTourPriceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  padding: 12px 0;
  color: #fff;
  background-color: #044476;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
`;

export const StyledTourPriceDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  font-size: 12px;
  color: #9eb3c7;
`;

export const StyledTourMoreInfoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #044476;
  border: none;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
  color: #fff;

  & > * {
    transform: ${props => props.$isActive ? "rotate(-90deg)" : "rotate(90deg)"};
  }
`;

export const StyledTourBadges = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  left: 12px;
  top: 12px;
`;

export const StyledTourIsNew = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #5ba554;
  color: #fff;
  font-size: 14px;
`;

export const StyledTourIsLastMinute = styled(StyledTourIsNew)`
  background-color: #c62828;
`;
