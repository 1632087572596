import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from '@material-ui/core';

import useLocalization from '../../../../../../contexts/localization/hook';
import config from '../../../../../../config';
import { setAppConfigs } from '../../../../../../store/appConfigs/actions';

import {
    Wrapper,
    Block,
    ExitToAppIcon,
    AccountCircleIcon,
    EnterBlock,
    Dropdown,
    StyledLink,
    LinkForAgents,
    Component,
    Button,
    ClientName,
    ClientLogin,
    ClientBalanceBlock,
    ClientBalanceTitle,
    ClientBalanceValue,
    ExitButton,
    Phone,
} from './components';
import SelectCurrencies from './parts/currencies';
import SelectLanguages from './parts/languages';
import SearchOrder from '../../../../searchOrder';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import Contacts from '../../contacts';

const DesktopNav = () => {
    const { isAuth, currency } = useSelector(store => store.appConfigs);
    const user = useSelector(state => state.clientData);
    const { width } = useWindowDimensions();

    const { t } = useLocalization();
    const clickOutside = useRef();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);

    const { agentsLink } = config.envConfigs;

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleLogout = () => {
        dispatch(
            setAppConfigs({
                isAuth: false,
            })
        );

        setIsActive(false);
        window.localStorage.removeItem('token');
        window.location.href = '/';
    };

    const handleClick = e => {
        if (clickOutside.current?.contains(e.target)) {
            return;
        }

        setIsActive(false);
    };

    return (
        <Wrapper>
            {!isAuth && (
                <Block>

                    {width >= 960 && <Contacts />}

                    {width >= 960 && <SearchOrder />}

                    <SelectLanguages />

                    <SelectCurrencies />

                    <EnterBlock onClick={() => setIsActive(true)}>
                        <ExitToAppIcon />
                    </EnterBlock>

                    <Dropdown active={isActive} ref={clickOutside} right>
                        <StyledLink to="/login" text={t.log_in} onClick={() => setIsActive(false)} />

                        <Divider />

                        <StyledLink to="/registration" text={t.registration} onClick={() => setIsActive(false)} />

                        {width < 960 && width >= 600 && (
                            <>
                                <Divider />
                                <SearchOrder desktop={true} />
                            </>
                        )}
                        <Divider />

                        <LinkForAgents href={`${agentsLink}`}>{t.agents}</LinkForAgents>
                    </Dropdown>
                </Block>
            )}

            {isAuth && (
                <Component>
                    <SelectLanguages />

                    <SelectCurrencies />

                    <Button onClick={() => setIsActive(true)} active={isActive}>
                        <AccountCircleIcon />
                    </Button>

                    <Dropdown active={isActive} ref={clickOutside}>
                        <ClientName>
                            {user.firstName} {user.lastName}
                        </ClientName>
                        <ClientLogin>{user.login}</ClientLogin>
                        <ClientBalanceBlock>
                            <ClientBalanceTitle>{t.your_id}:</ClientBalanceTitle>
                            <ClientBalanceValue>{user.id}</ClientBalanceValue>
                        </ClientBalanceBlock>
                        <Divider />

                        <ClientBalanceBlock>
                            <ClientBalanceTitle>{t.balance}:</ClientBalanceTitle>

                            <ClientBalanceValue>
                                {user.balance || 0}
                                <span>{user.currency || currency}</span>
                            </ClientBalanceValue>
                        </ClientBalanceBlock>

                        <Divider />

                        <StyledLink to="/orders" onClick={() => setIsActive(false)} text={t.orders} />

                        <Divider />

                        <StyledLink to="/transactions" onClick={() => setIsActive(false)} text={t.transactions} />

                        <Divider />

                        <StyledLink to="/userProfile" onClick={() => setIsActive(false)} text={t.my_profile} />

                        <Divider />

                        <StyledLink
                            to="/rechargeBalance"
                            onClick={() => setIsActive(false)}
                            text={t.recharging_balance}
                        />

                        <Divider />

                        <ExitButton onClick={() => handleLogout()}>{t.log_out}</ExitButton>
                    </Dropdown>
                </Component>
            )}
        </Wrapper>
    );
};

export default DesktopNav;
