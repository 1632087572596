import { makeStyles } from '@material-ui/core/styles';
import vectorStroke from '../../images/vector-stroke.png';
import iconsTime from '../../images/icons-time.png';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '1272px',
        margin: 'auto',
        position: 'relative',
    },
    rootContainer: {
        width: '895px',
    },
    rootRoute: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        boxShadow: '0 4px 10px 1px rgba(0, 59, 110, 0.1)',
        position: 'relative',
        overflow: 'hidden',
    },
    bolderLeftLine: {
        width: '62px',
        height: '89%',
        borderRadius: '50%',
        position: 'absolute',
        top: '62px',
        left: '-52px',
    },
    routeContainer: {
        marginTop: '24px',
    },
    routeTitleFirst: {
        padding: '16px',
    },
    routeTitleSecond: {
        padding: '16px',
        textAlign: 'end',

        '@media(max-width: 959px)': {
            textAlign: 'start',
        },
    },
    departureTimeBlock: {
        fontSize: '12px',
        color: '#9EB3C7',
    },
    departureRouteBlock: {
        fontWeight: '500',
        fontSize: '16px',
        color: '#404B5A',

        '@media(max-width: 959px)': {
            fontSize: '14px',
        },
    },
    travelTimeLabel: {
        color: '#9EB3C7',
        fontSize: '12px',

        '@media(max-width: 959px)': {
            fontSize: '10px',
        },
    },
    travelTime: {
        color: '#404B5A',
        fontSize: '16px',
        fontWeight: 'bold',

        '@media(max-width: 959px)': {
            fontWeight: 'normal',
            fontSize: '14px',
        },
    },
    segmentBlock: {
        padding: '16px 16px 16px 32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media screen and (max-width: 959px)': {
            padding: '0 0 16px 0 !important',
        },
    },
    flightData: {
        fontSize: '14px',
        color: '#212121',
        order: 1,

        '@media(max-width: 959px)': {
            width: '100%',
        },
    },
    flightNumberAndTime: {
        color: '#404B5A',
        fontSize: '14px',
        fontWeight: 'bold',

        '@media(max-width: 959px)': {
            display: 'none',
        },
    },
    flightNumber: {
        color: '#404B5A',
        fontSize: '14px',
        fontWeight: 'bold',

        '@media(max-width: 959px)': {
            color: '#9EB3C7',
            fontSize: '10px',
            fontWeight: 'normal',
        },
    },
    flightTimes: {
        borderRadius: '14px',
        border: '1px solid #044476',
        order: 3,
        marginRight: '30px',
        padding: '8px 16px',

        '@media screen and (max-width: 1279px)': {
            border: 'none',
        },
        '@media screen and (max-width: 959px)': {
            order: 2,
            border: 'none',
            marginRight: 0,
            borderBottom: '1px solid #D2D2D2',
            borderRadius: 0,
            padding: 0,
            marginBottom: 8,
        },
    },
    flightInfo: {
        order: 3,
        lineHeight: '1.6',
        textAlign: 'right',

        '@media screen and (max-width: 959px)': {
            order: 3,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    departureData: {
        width: '20%',
    },
    arrivalData: {
        width: '25%',
    },
    arrowBlock: {
        width: '8%',
        position: 'relative',
        top: '56px',
        height: '30px',
        backgroundImage: `url(${vectorStroke})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        backgroundSize: '40px 7px',
    },
    logoBlock: {
        paddingBottom: '10px',
    },
    segmentTime: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#404B5A',

        '@media(max-width: 959px)': {
            order: 2,
            fontSize: '26px',
        },
    },
    pointBlock: {
        fontSize: '14px',
        color: '#404B5A',
    },
    code: {
        color: '#9da8ae',
        paddingLeft: '5px',
        fontSize: '16px',
        fontWeight: 'bold',

        '@media(max-width: 959px)': {
            fontSize: '14px',
            paddingLeft: '10px',
            color: '#9EB3C7',
        },
    },
    classBlock: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#404B5A',

        '@media(max-width: 959px)': {
            fontSize: '12px',
        },
    },
    baggageBlock: {
        fontSize: '12px',
        color: '#404B5A',

        '@media(max-width: 959px)': {
            fontSize: '10px',
        },
    },
    showRulesBlock: {
        color: '#044476',
        fontSize: '14px',
        cursor: 'pointer',
    },
    transferContainer: {
        width: '100%',
        borderTop: 'solid 2px #f2f4f5',
        borderBottom: 'solid 2px #f2f4f5',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
    },
    transferBlock: {
        width: '16px',
        minWidth: '16px',
        height: '16px',
        backgroundImage: `url(${iconsTime})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom left',
        backgroundSize: '16px 16px',
    },
    transferData: {
        fontSize: '14px',
        color: '#212121',
        paddingLeft: '10px',
        position: 'relative',
    },
    priceFixedContainer: {
        position: 'absolute',
        left: '960px',
        top: '164px',
    },
    priceContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    priceBlock: {
        padding: '16px',
        backgroundColor: '#fff',
        borderRadius: '18px',
        boxShadow: '0 0 5px 1px rgba(0, 59, 110, .1)',
    },
    flightTicketRow: {
        display: 'flex',
        justifyContent: 'space-between',

        '@media (max-width: 1279px)': {
            alignItems: 'center',
        },
    },
    flightTicket: {
        fontSize: '14px',
        color: '#404B5A',
    },

    flightTicketAmount: {
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
        color: '#404B5A',

        '@media(max-width: 1279px)': {
            display: 'flex',
            justifyContent: 'flex-start',
        },
    },
    totalTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#404B5A',
    },
    priceFeesBlock: {
        marginTop: '8px',
    },
    partnerFeesValue: {
        fontSize: '18px',
        fontWeight: '500',
        textAlign: 'right',
    },
    partnerFeesValueBook: {
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'right',
    },
    partnerFeesTitleBook: {
        marginLeft: '8px',
        fontSize: '14px',
        textAlign: 'left',
    },
    partnerFeesTitle: {
        fontSize: '18px',
        textAlign: 'left',
    },
    priceTotalBlock: {
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    totalPrice: {
        fontSize: '20px',
        fontWeight: 'bold',
        // textAlign: 'right',
        color: '#044476',
    },
    priceIcon: {
        width: '16px',
        height: '16px',
        color: '#cfd8dc',
        cursor: 'pointer',
        position: 'relative',
        top: '3px',
    },
    totalPriceText: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    amountPassengersBlock: {
        width: '100%',
    },
    passBlock: {
        fontSize: '14px',
        color: '#212121',
    },
    passItem: {
        paddingTop: '8px',
    },
    passAmountTitle: {
        width: '50%',
    },
    passAmountValue: {
        width: '50%',
        textAlign: 'right',
        float: 'right',
    },
    passengersContainer: {
        flexGrow: 1,
    },
    passengersBlock: {
        borderRadius: '8px',
        padding: '16px 16px 8px',
    },
    passengersTitle: {
        fontSize: '28px',
        fontWeight: 'bold',
        padding: '24px 0 8px',
        color: '#404B5A',
    },
    partnerFeesTitleField: {
        fontSize: '24px',
        fontWeight: '500',
        paddingBottom: '8px',
    },
    payerTitle: {
        fontSize: '24px',
        color: '#404B5A',
        fontWeight: '500',
        paddingBottom: '8px',
    },
    payerMess: {
        fontSize: '14px',
        color: '#404B5A',
        paddingBottom: '8px',
    },
    passengerContainer: {
        paddingTop: '16px',
    },
    passengersTypeTitle: {
        fontSize: '19px',
        fontWeight: '500',
        color: '#404B5A',
        paddingBottom: '16px',
    },
    selectPassenger: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#84b6df',
        paddingBottom: '16px',
        paddingRight: '16px',
        cursor: 'pointer',
    },
    savePassengerBlock: {
        position: 'relative',
        paddingBottom: '16px',
    },
    passengersListButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    savePassengerItem: {
        textAlign: 'right',
        fontSize: '16px',
        fontWeight: '500',
    },
    savingProgress: {
        position: 'absolute',
        top: '9%',
        left: '28%',
        color: '#ffab91',
        cursor: 'text',
    },
    bonusCardContainer: {
        width: '795px',
    },
    bonusCard: {
        paddingBottom: '32px',
        paddingTop: '16px',
        color: '#7e878b',
        fontSize: '16px',
    },
    line: {
        width: '100%',
        height: '2px',
        backgroundColor: '#f2f4f5',
        margin: '16px 0',
    },
    bonusCardCheckbox: {
        width: '4%',
    },
    bonusCardTitle: {
        width: '96%',
    },
    bonusCardInput: {
        paddingTop: '16px',
    },
    submitBlock: {
        width: '100%',
        padding: '0 0 64px 0',
        textAlign: 'center',

        '@media screen and (max-width: 959px)': {
            padding: '12px 24px 64px',
        },
    },
    agreementBookTextBlock: {
        paddingTop: '24px',
    },
    agreementBookText: {
        color: '#404B5A',
        fontSize: '16px',
    },
    linkToLoginForBooking: {
        color: '#404B5A',
        fontSize: '16px',
        textAlign: 'center',
    },
    agreementBookLink: {
        color: '#044476',
        fontSize: '16px',
        cursor: 'pointer',
        padding: '0 4px',
    },
    techStopContainer: {
        paddingLeft: '25px',
        paddingTop: '19px',
    },
    techStopBlock: {
        borderRadius: '4px',
        fontSize: '14px',
        border: 'solid 1px #cfd8dc',
        backgroundColor: '#fafbfc',
        padding: '8px 24px 8px 12px',
    },
    tehStopTitle: {
        fontWeight: '500',
        paddingRight: '4px',
    },
    modalRoot: {
        display: 'flex',
        height: '685px',
        overflow: 'auto !important',
    },
    descriptionRulesTitle: {
        fontSize: '24px',
        fontWeight: '500',
        backgroundColor: '#f2f4f5',
        paddingBottom: '24px',
    },
    rulesDescriptionContainer: {
        width: '38%',
        paddingTop: '23px',
        paddingLeft: '32px',
        paddingRight: '32px',
        backgroundColor: '#f2f4f5',
        fontSize: '16px',
    },
    rulesBlock: {
        marginBottom: '16px',
    },
    rulesContainer: {
        width: '62%',
        paddingTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    rulesTitle: {
        fontSize: '24px',
        fontWeight: '500',
        paddingBottom: '24px',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
    },
    citizenshipBlock: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        fontSize: '14px',
        position: 'relative',

        cursor: 'pointer',

        '&:hover': {
            background: '#fbe9e7',
        },

        '&:active': {
            background: '#ffccbc',
        },
    },
    citizenshipItem: {
        width: '180px',
    },
    citizenshipItemCode: {
        width: '24px',
        height: '20px',
        position: 'absolute',
        top: '7px',
        right: '10px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '20px 24px',
    },
    formControlCitizenship: {
        position: 'relative',
    },
    labelCitizenship: {
        fontSize: '12px',
        color: '#9da8ae',
        position: 'absolute',
        top: '8px',
        left: '16px',
    },
    citizenshipInputCode: {
        width: '24px',
        height: '24px',
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '24px 24px',
    },
    sessionContainer: {
        paddingLeft: '8px',
        paddingBottom: '8px',
    },
    selectBlock: {
        marginBottom: '16px',
    },
    linkBackBlock: {
        marginBottom: '16px',
    },
    bookingContainer: {
        marginTop: '24px',
    },
    blockLeft: {
        order: 1,
        '@media screen and (max-width: 959px)': {
            order: 2,
        },
    },
    blockRight: {
        order: 2,
        '@media screen and (max-width: 959px)': {
            order: 1,
        },
    },
    blockPassengers: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',

        '@media screen and (max-width: 959px)': {
            padding: '0 !important',
        },
    },
    emptyInputBlock: {
        '@media screen and (max-width: 959px)': {
            display: 'none',
        },
    },
    segmentsContainer: {
        '@media screen and (max-width: 959px)': {
            padding: '16px',
        },
    },
    feesBlockButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    feesBlockTitle: {
        fontSize: '18px',
    },
    chevronDown: {
        transform: 'rotate(90deg)',
        transition: 'transform .3s ease',
    },
    chevronUp: {
        transform: 'rotate(-90deg)',
        transition: 'transform .3s ease',
    },
    showFeesBlock: {
        overflow: 'hidden',
        maxHeight: '100px',
        transition: 'max-height .3s linear',
    },
    hideFeesBlock: {
        overflow: 'hidden',
        maxHeight: 0,
        transition: 'max-height .3s linear',
    },
    divider2: {
        borderBottom: '1px solid #404B5A',
        width: '96%',
        height: '1px',
        margin: '0 auto',

        '@media(max-width: 599px)': {
            width: '92%',
            borderBottom: '1px solid #D2D2D2',
        },
    },
    divider1: {
        display: 'none',

        '@media(max-width: 599px)': {
            display: 'block',
            width: '92%',
            margin: '0 auto',
            height: '1px',
            borderBottom: '1px solid #D2D2D2',
        },
    },
    airCraft: {
        display: 'none',

        '@media(max-width: 599px)': {
            display: 'block',
            color: '#9EB3C7',
            fontSize: '10px',
        },
    },
    departurtime: {
        '@media(max-width: 599px)': {
            display: 'none',
        },
    },
    departCity: {
        '@media(max-width: 599px)': {
            color: '#9EB3C7',
            fontSize: '12px',
        },
    },
    departAirport: {
        '@media(max-width: 599px)': {
            color: '#9EB3C7',
            fontSize: '12px',
        },
    },
    terminal: {
        fontSize: '12px',
        color: '#9EB3C7',
    },
    contactBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    departureTimeOnTop: {
        display: 'none',
        '@media(max-width: 959px)': {
            display: 'block',
            marginTop: '8px',
            color: '#404B5A',
            fontSize: '12px',
        },
    },
    formBlock: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    registerText: {
        fontSize: '16px',
        color: '#404B5A',
    },
    agreementTheBook: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '32px',
        padding: '16px 24px 0',
    },
}));
