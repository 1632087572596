import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Wrapper, Backdrop, Modal, Header, LeftButton, Title, CloseButton, CloseIcon, Body } from './components';

const ModalComponent = ({
    children,
    active,
    onClose,
    title,
    leftButtonText,
    leftButtonOnClick,
    flexCenter,
    maxWidth = 1280,
    fixedWidth = false,
}) => {
    const backdropRef = useRef(null);

    useEffect(() => {
        if (active) {
            document.body.classList.add('hidden');
        } else {
            document.body.classList.remove('hidden');
        }
    }, [active]);

    const handleClose = () => {
        onClose();
    };

    const handleBackdropClick = event => {
        if (event.target === backdropRef.current) {
            handleClose();
        }
    };
    if (!active) return null;

    return ReactDOM.createPortal(
        <Wrapper isShow={active}>
            <Backdrop isShow={active} onClick={handleBackdropClick} ref={backdropRef}>
                <Modal isShow={active} maxWidth={maxWidth} fixedWidth={fixedWidth}>
                    <Header>
                        {leftButtonText && <LeftButton onClick={leftButtonOnClick}>{leftButtonText}</LeftButton>}

                        <Title>{title}</Title>

                        <CloseButton onClick={handleClose} size="small">
                            <CloseIcon />
                        </CloseButton>
                    </Header>

                    <Body flexCenter={!!flexCenter}>{children}</Body>
                </Modal>
            </Backdrop>
        </Wrapper>,
        document.body
    );
};

export default ModalComponent;
