export default [
    {
        code: 'TAS',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Ташкент Международный аэропорт',
            en: 'Tashkent International Airport',
            tj: 'Тошканд Фурудгохи Байналмиллали',
            uz: 'Ташкент Международный аэропорт',
        },
        isMain: 1,
    },
    {
        code: 'UGC',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Ургенч',
            en: 'Urgench',
            tj: 'Ургенч',
            uz: 'Ургенч',
        },
        isMain: 1,
    },
    {
        code: 'NVI',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Навои',
            en: 'Navoi',
            tj: 'Навои',
            uz: 'Навои',
        },
        isMain: 1,
    },
    {
        code: 'NMA',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Наманган',
            en: 'Namangan',
            tj: 'Наманган',
            uz: 'Наманган',
        },
        isMain: 1,
    },
    {
        code: 'BHK',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Бухара',
            en: 'Bukhara',
            tj: 'Бухара',
            uz: 'Бухара',
        },
        isMain: 1,
    },
    {
        code: 'SKD',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Самарканд Международный аэропорт',
            en: 'Samarkand International Airport',
            tj: 'Самарканд Фурудгохи Байналмиллали',
            uz: 'Самарканд Международный аэропорт',
        },
        isMain: 1,
    },
    {
        code: 'KSQ',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Карши',
            en: 'Karshi',
            tj: 'Карши',
            uz: 'Карши',
        },
        isMain: 1,
    },
    {
        code: 'TMJ',
        countryCode: 'UZ',
        countryName: {
            ru: 'Узбекистан',
            en: 'Uzbekistan',
            tj: 'Ӯзбекистон',
            uz: 'Узбекистан',
        },
        value: {
            ru: 'Термез',
            en: 'Termez',
            tj: 'Термез',
            uz: 'Термез',
        },
        isMain: 1,
    },
];
