import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 959px) {
    width: 100%;
  }
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 570px;

  @media (max-width: 959px) {
    width: 100%;
  }
`;

export const FormTitle = styled.div`
  font-size: 22px;
  color: #404b5a;
  text-align: center;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: 1279px) {
    font-size: 36px;
  }

  @media (max-width: 959px) {
    font-size: 24px;
  }
`;

export const FormSubtitle = styled.div`
  font-size: 14px;
  color: #404b5a;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 1279px) {
    font-size: 16px;
  }
`;

export const FormGroup = styled.div`
  margin: 0 auto 32px;
  width: 100%;
`;

export const FormSubmitButton = styled.button`
  display: block;
  margin: 0 auto 20px;
  height: 48px;
  min-width: 184px;
  background-color: #044476;
  border: none;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
`;
