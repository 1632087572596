const defaultLanguages = {
    ru: {
        ru: 'Русский',
        tj: 'Таджикский',
        en: 'Английский',
        uz: 'Узбекский',
    },
    tj: {
        ru: 'Русӣ',
        tj: 'Тоҷикӣ',
        en: 'Англисӣ',
        uz: 'Узбекӣ',
    },
    en: {
        ru: 'Russian',
        tj: 'Tajik',
        en: 'English',
        uz: 'Uzbek',
    },
    uz: {
        ru: 'Rus',
        tj: 'Tojik',
        en: 'Ingliz',
        uz: 'O\'zbek',
    }
};

export default defaultLanguages;
