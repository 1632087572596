import { Tune } from "@material-ui/icons";
import styled from "styled-components";

export const DesktopBlock = styled.div`
  padding: 8px 16px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);
  background-color: #fff;

  @media (max-width: 959px) {
    display: none;
  }
`;

export const MobileBlock = styled.div`
  display: none;

  @media (max-width: 959px) {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 59, 110, 0.1);

  @media (max-width: 959px) {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  border: none;
  background-color: #044476;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 16px;
`;

export const FilterIcon = styled(Tune)`
  margin-right: 8px;
`;

export const FilterButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid #044476;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #404b5a;
  box-shadow: 0px 0px 20px 6px rgba(238, 238, 238, 0.07);
`;
