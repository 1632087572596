import React from "react";

import useLocalization from "../../../../contexts/localization/hook";

import TourImagesComponent from "../TourImagesComponent";

import { CityBlock, DescriptionBlock, TitleBlock, TypeBlock, TypesBlock, Wrapper } from "./components";

const TourInfoComponent = ({ name, city, description, types, images }) => {
  const { language } = useLocalization();

  return (
    <Wrapper>
      <TitleBlock>
        {name[language]}
      </TitleBlock>

      <CityBlock>
        {city.name[language]}
      </CityBlock>

      <TourImagesComponent images={images} />
        <DescriptionBlock>
          {description[language]}
        </DescriptionBlock>

        <TypesBlock>
          {types.map(type => (
            <TypeBlock key={type.id}>{type.name[language]}</TypeBlock>
          ))}
        </TypesBlock>
      </Wrapper>
    );
};

export default TourInfoComponent;
