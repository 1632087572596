import React from 'react';

import { useSelector } from 'react-redux';

import useLocalization from '../../../../contexts/localization/hook';

import { normalizePrice } from '../../../../lib/normalize';

import FilterBlock from '../../resultPage/filters/parts/filterBlock';
import RangeSlider from '../../resultPage/filters/parts/rangeSlider';

const ToursFilterPriceComponent = ({ data, selected, onChange }) => {
  const { t } = useLocalization();

  const { currency } = useSelector(state => state.appConfigs);

  const handleChange = value => onChange({ price: { data, selected: value } });

  const TitleComponent = (minValue, maxValue) => {
    return (
      <div>
        {normalizePrice(minValue)} — {normalizePrice(maxValue)} {currency}
      </div>
    );
  };

  return (
    <FilterBlock title={t.price} isOpen borderBottom>
      <RangeSlider
        range={data}
        filter={selected}
        handleChange={handleChange}
        titleComponent={TitleComponent}
      />
    </FilterBlock>
  );
};

export default ToursFilterPriceComponent;
