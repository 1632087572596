import React, { useEffect, useState } from "react";

import { Container } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import envConfigs from "../../config/envConfigs";

import API from "../../lib/api";

import { setAppConfigs } from "../../store/appConfigs/actions";

import useLocalization from "../../contexts/localization/hook";

import Loading from "../../components/design_v2/ui/loading";
import TourComponent from "../../components/design_v2/tour/TourComponent";

const TourPage = () => {
  const dispatch = useDispatch();
  const { language } = useLocalization();

  const { tourId } = useParams();

  const [tour, setTour] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(setAppConfigs({ showSearchBlock: false }));

    API.getTour(tourId).then(response => {
      setTour(response.data);
      setIsLoading(false);
      document.title = `${response.data.name[language]} | ${envConfigs.appTitle}`;
    });

    return () => {
      dispatch(setAppConfigs({ showSearchBlock: true }));
    };
  }, [tourId]);

  return (
    <Container fixed>
      {isLoading ? <Loading /> : <TourComponent tour={tour} />}
    </Container>
  );
};

export default TourPage;
