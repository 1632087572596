import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import _ from 'lodash';

import useLocalization from '../../../../contexts/localization/hook';
import API from '../../../../lib/api';
import Modal from '../../../design_v2/ui/modal';
import Loading from '../../../design_v2/ui/loading';
import {
    PassengerInfoBlock,
    NoPassengers,
    PassengerItem,
    PassengerName,
    PassengerCitizenship,
    PassengerGender,
    PassengerBirthday,
    PassengerDocument,
    PassengerEmail,
    PassengerPhone,
    ButtonsBlock,
    ChooseButton,
    DeleteButton,
} from './components';
import { F, M, NP, PS, PSP, SR } from '../../../../containers/booking/constants';
import { CircularProgress } from '@material-ui/core';

const SavedPassengers = ({
    isActive,
    onClose,
    passengerIndex,
    onSelectAvailableDocumentTypes,
    onUpdateCitizenship,
}) => {
    const { t, language } = useLocalization();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [passengersList, setPassengersList] = useState([]);
    const [removingPassenger, setRemovingPassenger] = useState(null);

    useEffect(() => {
        if (isActive) {
            getPassengers();
        } else {
            setIsLoading(true);
        }
    }, [isActive]);

    const getPassengers = async () => {
        API.getPassengersList().then(response => {
            setPassengersList(response);
            setIsLoading(false);
        });
    };

    const handleClickPassenger = passenger => {
        const {
            gender,
            surname,
            date_of_birth,
            middle_name,
            name,
            email,
            phone,
            document_number,
            document_type,
            expiration_date,
        } = passenger;

        const citizenship = JSON.parse(passenger.citizenship);

        dispatch(
            change('booking', `passengers[${passengerIndex}].defaultPassengerData`, {
                passenger,
            })
        );

        if (email) {
            dispatch(change('booking', `passengers[${passengerIndex}].email`, email));
        }

        if (phone) {
            dispatch(change('booking', `passengers[${passengerIndex}].phone`, phone));
        }

        dispatch(change('booking', `passengers[${passengerIndex}].name`, name));
        dispatch(change('booking', `passengers[${passengerIndex}].surname`, surname));
        dispatch(change('booking', `passengers[${passengerIndex}].gender`, gender));
        dispatch(change('booking', `passengers[${passengerIndex}].citizenship`, citizenship.code));
        onUpdateCitizenship(citizenship, passengerIndex);
        dispatch(change('booking', `passengers[${passengerIndex}].date_of_birth`, date_of_birth));
        dispatch(change('booking', `passengers[${passengerIndex}].middle_name`, middle_name));

        const actualAvailableDocumentTypes = onSelectAvailableDocumentTypes(citizenship, passengerIndex);

        if (
            _.findIndex(actualAvailableDocumentTypes, function (o) {
                return o === document_type;
            }) === -1
        ) {
            const defaultDocumentType = _.first(actualAvailableDocumentTypes);

            dispatch(change('booking', `passengers[${passengerIndex}].document_type`, defaultDocumentType));
            dispatch(change('booking', `passengers[${passengerIndex}].document_number`, ''));
            dispatch(change('booking', `passengers[${passengerIndex}].expiration_date`, ''));
        } else {
            dispatch(change('booking', `passengers[${passengerIndex}].document_type`, document_type));
            dispatch(change('booking', `passengers[${passengerIndex}].document_number`, document_number));
            dispatch(change('booking', `passengers[${passengerIndex}].expiration_date`, expiration_date));
        }

        onClose();
    };

    const handleClickDelete = passenger => {
        if (!removingPassenger) {
            setRemovingPassenger(passenger.id);

            API.deletePassenger(passenger.id).then(response => {
                setRemovingPassenger(null);
                setPassengersList(passengersList.filter(x => x.id !== passenger.id));
            });
        }
    };

    const renderPassengers = () => {
        if (passengersList.length === 0) {
            return <NoPassengers>{t.no_passengers}</NoPassengers>;
        }

        const allDocuments = [
            { value: PS, label: t.russian_passport },
            { value: SR, label: t.birth_certificate },
            { value: PSP, label: t.international_passport },
            { value: NP, label: t.foreign_document },
        ];

        const genderItem = [
            { value: M, label: t.male },
            { value: F, label: t.female },
        ];

        return passengersList.map((passenger, index) => {
            const citizenship = JSON.parse(passenger.citizenship);

            return (
                <PassengerItem key={index}>
                    <PassengerInfoBlock>
                        <PassengerName>
                            {passenger.surname} {passenger.name} {passenger.middle_name}
                        </PassengerName>
                        <PassengerCitizenship>
                            <span>{t.citizenship}:</span> {citizenship.name[language]} ({citizenship.code})
                        </PassengerCitizenship>
                        <PassengerGender>
                            <span>{t.gender}:</span> {genderItem.find(x => x.value === passenger.gender)?.label}
                        </PassengerGender>
                        <PassengerBirthday>
                            <span>{t.date_of_birth}:</span> {passenger.date_of_birth}
                        </PassengerBirthday>
                        <PassengerDocument>
                            <span>{t.document}:</span>{' '}
                            {allDocuments.find(x => x.value === passenger.document_type)?.label},{' '}
                            {passenger.document_number}, {passenger.expiration_date}
                        </PassengerDocument>
                        <PassengerEmail>
                            <span>{t.email}:</span> {passenger.email}
                        </PassengerEmail>
                        <PassengerPhone>
                            <span>{t.phone_number}:</span> {passenger.phone}
                        </PassengerPhone>
                    </PassengerInfoBlock>

                    <ButtonsBlock>
                        <ChooseButton type="button" onClick={() => handleClickPassenger(passenger)}>
                            {t.choose}
                        </ChooseButton>

                        <DeleteButton type="button" onClick={() => handleClickDelete(passenger)}>
                            {removingPassenger === passenger.id ? (
                                <CircularProgress size={14} color="inherit" />
                            ) : (
                                t.delete
                            )}
                        </DeleteButton>
                    </ButtonsBlock>
                </PassengerItem>
            );
        });
    };

    return (
        <Modal active={isActive} onClose={onClose} title={t.all_passengers} maxWidth={600} fixedWidth>
            {isLoading ? <Loading /> : renderPassengers()}
        </Modal>
    );
};

export default SavedPassengers;
