import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
    AirlineLogo,
    AirlineLogoBlock,
    FlightInfo,
    DurationBlock,
    FlightInfoGrid,
    PentagonShape,
    RouteBlock,
    RouteMainInfo,
    RouteTitle,
    RouteTitleContainer,
    TimeRouteBlock,
    Wrapper,
    TransfersArrowImg,
    StringSpan,
    HideRouteTariffContainer,
    StyledHideTariffsButton,
    TariffCloseBlock,
    ChangeTariffContainer,
    TariffChangeBlock,
    LowcostCharterContainer,
    Charter,
    Lowcost,
    ClassBlock,
    AdditionalInformationShort,
    InlineBlock,
    FreeSeatsLabelBlock,
    ButtonContainer,
    SupplierBlock,
    AmountSubmitBlock,
    AmountBlock,
    ChoosedButtonBlock,
    ChoosedButton,
    OrderInfoGrid,
    RouteAdditionallyInfo,
    ArrivalInfo,
    SpecialText,
} from './components';

import { COLOR_THEME } from '../../../../../store/resultData/constants';
import { getFlightIcon, getSupplierIcon, getTransferArrowIcon } from '../../../../../lib/getImage';
import { getDayMonthYearFormat, getServiceClass } from '../../utils';
import FlightInfoIcons from '../../flightInfoIcons';
import noun from 'plural-ru';
import AdditionallyForm from '../../additionallyForm';
import { normalizePrice } from '../../../../../lib/normalize';
import useLocalization from '../../../../../contexts/localization/hook';
import { RT } from '../../../../../containers/search/constants';

const DesktopRecommendation = ({ recommendation, onBookingClick, getBrandFares, getFareRules, specialText }) => {
    const { brandFares, included, session } = useSelector(state => state.resultData);
    const { currency } = useSelector(state => state.appConfigs);
    const { flightType } = useSelector(state => state.searchParams);
    const { t, language } = useLocalization();

    const [showAdditionalInfoItems, setShowAdditionalInfoItems] = useState(
        recommendation.routes.map(route => {
            return {
                showDetailsTariffsButtons: false,
                showDetails: false,
                showTariffs: false,
            };
        })
    );

    const getCityString = (airportCode, cityCode) => {
        const { city, airport } = included;

        const cityName = city[cityCode].name[language];
        const airportName = airport[airportCode].name[language];

        if (cityName === airportName) {
            return cityName;
        } else {
            return (
                <div>
                    <div>{cityName},</div>
                    <div>{airportName} </div>
                </div>
            );
        }
    };

    const renderDepartureInfo = departureSegment => {
        const { time, airport, city } = departureSegment.departure;

        return (
            <FlightInfo>
                <RouteBlock>{getDayMonthYearFormat(time, language)}</RouteBlock>
                <TimeRouteBlock>{time.split(' ')[1]}</TimeRouteBlock>
                <RouteBlock>{getCityString(airport, city)}</RouteBlock>
            </FlightInfo>
        );
    };

    const renderDurationTime = duration => {
        const hours = Math.trunc(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);

        return (
            <span>
                {hours} {t.hour_short} {minutes} {t.minutes_short_3}
            </span>
        );
    };

    const renderTransfersArrow = segments => {
        const transferCount = segments.length - 1;

        const imgUrl = getTransferArrowIcon(transferCount);

        return <TransfersArrowImg imgUrl={imgUrl} />;
    };

    const renderTransfersInfo = segments => {
        const transfersCount = segments.length - 1;

        if (transfersCount === 0) {
            return <div>{t.no_transfers}</div>;
        }

        const TRANSFER_COUNT_LABEL = {
            [1]: t.transfer_1,
            [2]: t.transfer_2_4,
            [3]: t.transfer_2_4,
            [4]: t.transfer_2_4,
            [5]: t.transfer_5,
        };

        const transfersCodeList = segments
            .slice(0, -1)
            .map(segment => segment.arrival.airport)
            .join(', ');

        return (
            <div>
                <StringSpan>{transfersCount}</StringSpan>
                <StringSpan>{TRANSFER_COUNT_LABEL[transfersCount]}:</StringSpan>
                <StringSpan>{transfersCodeList}</StringSpan>
            </div>
        );
    };

    const renderDuration = route => {
        return (
            <DurationBlock>
                {renderDurationTime(route.duration)}
                {renderTransfersArrow(route.segments)}
                {renderTransfersInfo(route.segments)}
            </DurationBlock>
        );
    };

    const renderArrivalInfo = arrivalSegment => {
        const { time, airport, city } = arrivalSegment.arrival;

        return (
            <ArrivalInfo>
                <RouteBlock>{getDayMonthYearFormat(time, language)}</RouteBlock>
                <TimeRouteBlock>{time.split(' ')[1]}</TimeRouteBlock>
                <RouteBlock>{getCityString(airport, city)}</RouteBlock>
            </ArrivalInfo>
        );
    };

    const getFreeSeatsLabel = freeSeats => {
        if (freeSeats === 1) {
            return freeSeats + ' ' + t.free_seats_1;
        }

        if (freeSeats > 1 && freeSeats < 5) {
            return freeSeats + ' ' + t.free_seats_2_4;
        }

        if (freeSeats > 4 && freeSeats < 9) {
            return freeSeats + ' ' + t.free_seats_5;
        }

        if (freeSeats > 8) {
            return '9+ ' + t.free_seats_5;
        }
    };

    const hideRouteTariffDetails = routeIndex => {
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: false,
            showDetails: false,
            showTariffs: false,
        };
        setShowAdditionalInfoItems(newValues);
    };

    const handleShowRouteDetails = routeIndex => {
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: true,
            showDetails: true,
            showTariffs: false,
        };
        setShowAdditionalInfoItems(newValues);
    };

    const handleShowTariffs = (recId, routeIndex) => {
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: true,
            showDetails: false,
            showTariffs: true,
        };
        setShowAdditionalInfoItems(newValues);
        getBrandFares(recId);
    };

    const renderBaggage = (baggage, baggage_weight) => {
        return (
            <div style={{ fontSize: '12px', textAlign: 'right' }}>
                {baggage !== 0
                    ? `${t.baggage}: ${baggage} ${noun(baggage, t.place_one, t.place_more, t.place_many)} ${
                          baggage_weight ? `(${baggage_weight} ${t.weight_unit})` : ''
                      }`
                    : t.no_baggage}
            </div>
        );
    };

    const renderHandLuggage = (hand_luggage, hand_luggage_weight) => {
        return (
            <div style={{ fontSize: '12px', textAlign: 'right' }}>
                {hand_luggage !== 0
                    ? `${t.hand_luggage}: ${hand_luggage} ${noun(
                          hand_luggage,
                          t.place_one,
                          t.place_more,
                          t.place_many
                      )} ${hand_luggage_weight ? `(${hand_luggage_weight} ${t.weight_unit})` : ''}`
                    : null}
            </div>
        );
    };

    const toggleHover = (oldStatus, routeIndex) => {
        const status = !oldStatus;
        let newValues = [...showAdditionalInfoItems];
        newValues[routeIndex] = {
            showDetailsTariffsButtons: status,
            showDetails: false,
            showTariffs: false,
        };
        setShowAdditionalInfoItems(newValues);
    };

    const shortTariffInfo = (departureSegment, recId, route, has_branded_tariffs, lowcost) => {
        const { service_class, free_seats, baggage, baggage_weight, hand_luggage, hand_luggage_weight, is_charter } =
            departureSegment;
        const { showDetailsTariffsButtons, showDetails, showTariffs } = showAdditionalInfoItems[route.index];
        const routeIndex = route.index;

        const freeSeatsLabel = getFreeSeatsLabel(free_seats);

        if (showDetails || showTariffs) {
            return (
                <Grid item xs={12}>
                    <HideRouteTariffContainer onClick={() => hideRouteTariffDetails(routeIndex)}>
                        <StyledHideTariffsButton type="button" />
                        <TariffCloseBlock>{t.collapse}</TariffCloseBlock>
                    </HideRouteTariffContainer>
                </Grid>
            );
        }

        if (showDetailsTariffsButtons) {
            return (
                <Grid item xs={12}>
                    <ChangeTariffContainer>
                        <TariffChangeBlock onClick={() => handleShowRouteDetails(routeIndex)}>
                            {t.flight_details}
                        </TariffChangeBlock>

                        {has_branded_tariffs && (
                            <TariffChangeBlock onClick={() => handleShowTariffs(recId, routeIndex)}>
                                {t.change_tariff}
                            </TariffChangeBlock>
                        )}
                    </ChangeTariffContainer>
                </Grid>
            );
        }

        return (
            <>
                <Grid item xs={12}>
                    {is_charter && (
                        <LowcostCharterContainer>
                            <Charter>{t.charter}</Charter>
                        </LowcostCharterContainer>
                    )}
                </Grid>

                <Grid item xs={12}>
                    {lowcost && (
                        <LowcostCharterContainer>
                            <Lowcost>{t.lowcost}</Lowcost>
                        </LowcostCharterContainer>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <ClassBlock>{getServiceClass(service_class, t.class)}</ClassBlock>
                </Grid>

                <Grid item xs={12}>
                    <AdditionalInformationShort>
                        <InlineBlock>
                            <FlightInfoIcons data={departureSegment} />
                        </InlineBlock>
                        <FreeSeatsLabelBlock>{freeSeatsLabel}</FreeSeatsLabelBlock>
                    </AdditionalInformationShort>
                </Grid>

                <Grid item xs={12}>
                    {renderBaggage(baggage, baggage_weight)}
                </Grid>

                <Grid item xs={12}>
                    {renderHandLuggage(hand_luggage, hand_luggage_weight)}
                </Grid>

                <Grid item xs={12}>
                    <TariffChangeBlock onClick={() => toggleHover(showDetailsTariffsButtons, routeIndex)}>
                        {t.detail}
                    </TariffChangeBlock>
                </Grid>
            </>
        );
    };

    const getDepartureTitle = index => {
        if (flightType === RT) {
            if (index === 0) {
                return t.there_title;
            } else {
                return t.back_title;
            }
        }

        return t.departure_title;
    };

    const renderRoute = (recommendation, route, index) => {
        const { validating_supplier, has_branded_tariffs, brandRecId, brand_name, rec_id, lowcost } = recommendation;
        const { showDetails, showTariffs } = showAdditionalInfoItems[route.index];
        const brandFaresItem = brandFares[rec_id];
        let actualRoute = JSON.parse(JSON.stringify(route));

        if (brandFaresItem) {
            const currentBrandFare = brandFaresItem.find(item => item.rec_id === brandRecId);

            if (currentBrandFare) {
                actualRoute.segments = currentBrandFare.routes[index].segments;
            }
        }

        const departureSegment = _.first(actualRoute.segments);
        const arrivalSegment = _.last(actualRoute.segments);
        const themeColor = COLOR_THEME[index];
        const imgUrl = getFlightIcon(index);

        return (
            <Grid container key={index}>
                <Grid item xs={12}>
                    <RouteTitleContainer imgUrl={imgUrl}>
                        <RouteTitle themeColor={themeColor}>{getDepartureTitle(index)}</RouteTitle>
                    </RouteTitleContainer>
                </Grid>

                <Grid item xs={12}>
                    <RouteMainInfo themeColor={themeColor}>
                        <PentagonShape themeColor={themeColor} />

                        <Grid container>
                            <Grid item xs={6} sm={6} lg={3}>
                                <AirlineLogoBlock>
                                    <AirlineLogo imgUrl={getSupplierIcon(validating_supplier)} />
                                </AirlineLogoBlock>
                            </Grid>

                            <FlightInfoGrid item xs={12} sm={12} lg={6}>
                                <Grid container spacing={3} alignItems={'center'}>
                                    <Grid item xs={4}>
                                        {renderDepartureInfo(departureSegment)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderDuration(route)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {renderArrivalInfo(arrivalSegment)}
                                    </Grid>
                                </Grid>
                            </FlightInfoGrid>
                            <OrderInfoGrid item xs={6} sm={6} lg={3}>
                                <Grid container justifyContent={'flex-end'}>
                                    {shortTariffInfo(
                                        departureSegment,
                                        rec_id,
                                        actualRoute,
                                        has_branded_tariffs,
                                        lowcost
                                    )}
                                </Grid>
                            </OrderInfoGrid>
                        </Grid>
                    </RouteMainInfo>
                </Grid>

                <Grid item xs={12}>
                    {(showDetails || showTariffs) && (
                        <RouteAdditionallyInfo>
                            <AdditionallyForm
                                route={actualRoute}
                                recommendation={recommendation}
                                included={included}
                                routeIndex={index}
                                recId={rec_id}
                                brandRecId={brandRecId}
                                brandFares={brandFaresItem}
                                showTariffs={showTariffs}
                                showDetails={showDetails}
                                setShowRouteDetails={handleShowRouteDetails}
                                setShowTariffs={handleShowTariffs}
                                getFareRules={getFareRules}
                                brandName={brand_name}
                                currency={currency}
                            />
                        </RouteAdditionallyInfo>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderSupplierBlock = validatingSupplier => {
        const { supplier } = included;
        const supplierArray = _.find(supplier, { iata: validatingSupplier });
        const validatingSupplierName = supplierArray ? supplierArray.name[language] : null;

        return (
            <SupplierBlock>
                {validatingSupplierName && `${validatingSupplierName} — ${t.validates_flights}`}
            </SupplierBlock>
        );
    };

    const renderSubmit = recommendation => {
        const { brandRecId, rec_id } = recommendation;

        const recId = brandRecId || rec_id;

        return (
            <ChoosedButtonBlock>
                <ChoosedButton type="submit" onClick={() => onBookingClick(recId, session)}>
                    {t.choose}
                </ChoosedButton>
            </ChoosedButtonBlock>
        );
    };

    const renderButtonContainer = recommendation => {
        const { validating_supplier, total_price, brandRecId } = recommendation;

        let actualTotalPrice = total_price;

        if (brandRecId) {
            const { rec_id } = recommendation;
            const actualBrandFare = _.find(brandFares[rec_id], { rec_id: brandRecId });

            actualTotalPrice = actualBrandFare.total_price;
        }

        return (
            <ButtonContainer>
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                {renderSupplierBlock(validating_supplier)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <AmountSubmitBlock>
                            <AmountBlock>
                                <span>{normalizePrice(actualTotalPrice[currency])}</span> {currency}
                            </AmountBlock>

                            {renderSubmit(recommendation)}
                        </AmountSubmitBlock>
                    </Grid>
                </Grid>
            </ButtonContainer>
        );
    };

    return (
        <Wrapper>
            {specialText && <SpecialText>{specialText}</SpecialText>}
            {recommendation.routes.map((route, number) => renderRoute(recommendation, route, number))}
            {renderButtonContainer(recommendation)}
        </Wrapper>
    );
};

export default DesktopRecommendation;
