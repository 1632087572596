import styled from 'styled-components';
import { Tune } from '@material-ui/icons';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/closeIcon.svg';

export const Wrapper = styled.div``;

export const ButtonBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilterButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #044476;
    background-color: #fff;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: #044476;
    box-shadow: 0px 0px 20px 6px #eee;
`;

export const FilterIcon = styled(Tune)`
    margin-right: 8px;
`;

export const ActiveIndicator = styled.div`
    position: absolute;
    left: 30px;
    top: 8px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
`;

export const ModalBlock = styled.div`
    margin-top: -16px;
    padding: 0 8px;
`;

export const ButtonsBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 16px;
    position: sticky;
    bottom: 0;
`;

export const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    border: none;
    background-color: #84b6df;
    /* background-color: #35c768; */
    border-radius: 8px;
    padding: 16px;
    font-size: 16px;
    line-height: 1;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    width: 100%;
    letter-spacing: 1px;
`;

export const Icon = styled(CloseIcon)`
    margin-right: 4px;
`;
